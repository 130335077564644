@charset "UTF-8";
:root {
  --font-family: "Lato", sans-serif;
  --content-width: 82.5rem;
  --container-offset: 3.75rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --font-family: "Lato", sans-serif;
  --second-family: "Montserrat", sans-serif;
  --third-family: "Inter", sans-serif;
  --font3: "Open Sans", sans-serif;
  --main: rgb(42, 64, 123);
  --accent: #ea6709;
  --dark: #071126;
  --white: #fff;
  --light: #f2f4f7;
  --dark: #071126;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/Lato-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/Lato-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/Lato-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/Lato-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  font-size: 1.10990335vw;
}

@media (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 800px) {
  html {
    box-sizing: border-box;
    font-size: 2.089vw;
  }
}
@media (max-width: 550px) {
  html {
    box-sizing: border-box;
    font-size: 5.06991vw;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

p {
  margin: 0;
  padding: 0;
}

ul, li {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

input {
  color: var(--dark) !important;
  opacity: 1 !important;
  font-weight: 400 !important;
}

input::placeholder {
  color: rgba(7, 17, 38, 0.5) !important;
}

textarea {
  color: var(--dark) !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  outline: none;
}

textarea::placeholder {
  color: rgba(7, 17, 38, 0.5) !important;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 1.25rem;
  color: var(--dark);
  font-weight: 400;
  font-family: var(--font-family);
  line-height: 130%;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

@media (max-width: 800px) {
  .container {
    margin: 0 auto;
    padding: 0 1.25rem;
    max-width: 52.5rem;
    margin: 0 6px;
  }
}
@media (max-width: 550px) {
  .container {
    margin: 0 auto;
    padding: 0 0.625rem;
  }
}
.container-small {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10.75rem;
}

@media (max-width: 800px) {
  .container-small {
    margin: 0 auto;
    padding: 0 1.25rem;
    max-width: 52.5rem;
    margin: 0 6px;
  }
}
@media (max-width: 550px) {
  .container-small {
    margin: 0 auto;
    padding: 0 0.625rem;
  }
}
.site-container {
  overflow: hidden;
  position: relative;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  position: relative;
  border-radius: 0.625rem;
  width: 12.5rem;
  height: 3.125rem;
  background: var(--white);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--main);
}
.btn:hover {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
  background: var(--light);
  transition: background 0.2s ease;
}

.btn-light {
  position: relative;
  border-radius: 0.625rem;
  width: 12.5rem;
  height: 3.125rem;
  background: var(--white);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--main);
  border: 1px solid var(--main);
}
.btn-light:hover {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
  background: var(--light);
  transition: background 0.2s ease;
}

.btn-dark {
  position: relative;
  border-radius: 0.625rem;
  height: 3.125rem;
  background: var(--main);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--white);
}
.btn-dark:hover {
  box-shadow: 0 0 8px 0 rgba(42, 64, 123, 0.4);
  background: #324f9d;
  transition: background 0.2s ease;
}

.btn-color {
  width: 22.75rem;
  height: 3.625rem;
  border-radius: 0.625rem;
  background: var(--accent);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--white);
}
.btn-color:hover {
  box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
  background: #ff7919;
  transition: background 0.2s ease;
}

@media (max-width: 800px) {
  .btn-color {
    height: 3.25rem;
  }
}
@media (max-width: 550px) {
  .btn-color {
    height: 2.625rem;
    font-weight: 700;
    font-size: 1rem !important;
  }
}
.section {
  padding: 6.25rem 0;
}

.section-title {
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 3rem;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  color: var(--main);
  margin-bottom: 3.75rem;
  text-align: center;
}

@media (max-width: 800px) {
  .section {
    padding: 3.75rem 0;
  }
  .section-title {
    font-family: var(--second-family);
    font-size: 2rem;
    line-height: 100%;
    margin-bottom: 3.75rem;
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 550px) {
  .section {
    padding: 2.5rem 0;
  }
  .section-title {
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 130%;
    text-align: center;
    color: var(--main);
    margin-bottom: 1.25rem;
  }
}
.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-top: 0.875rem;
  z-index: 100;
}
.header__wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1.3125rem;
}
.header__contact-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  color: var(--white);
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--white);
  margin-bottom: 0.75rem;
}
.header__logo {
  width: 12.1875rem;
  height: auto;
  padding-top: 0.625rem;
}
.header__contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
  margin-bottom: 1.25rem;
}
.header__contact-item {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}
.header__contact-time p {
  font-family: var(--second-family);
  font-size: 0.875rem;
}
.header__contact-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.header__contact-language {
  position: relative;
  font-family: var(--second-family);
  font-size: 1rem;
  padding-right: 1.375rem;
  line-height: normal;
}
.header__contact-language::after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  content: ""; /* Выводим плюс */
  background: url("../img/language-arrow.svg") no-repeat center;
  background-size: contain;
  width: 0.875rem;
  height: 0.5rem;
}
.header__contact-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--white);
}
.header__contact-wrapper {
  flex: 0 0 55.4rem;
}
.header__bottom {
  display: flex;
  align-items: start;
  gap: 2.75rem;
}
.header__nav {
  flex: 0 0 55.03125rem;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.8);
  padding: 0.8125rem 4.0625rem 1.1125rem;
}
.header__nav-list {
  display: flex;
  align-self: center;
  justify-content: space-between;
}
.header__social {
  display: flex;
  gap: 0.5rem;
}
.header__mobile {
  display: none;
}
.header__contact-phone--img {
  display: none;
}

.nav__link {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
}
.nav__link:hover {
  -webkit-text-stroke: 1px var(--main);
}

.social-link {
  width: 1.5rem;
  height: 1.5rem;
}

.header__fixed {
  display: none;
  background: var(--white);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
}
.header__fixed.active {
  display: block;
}
.header__fixed .header__contact {
  display: block;
  margin-bottom: 0;
}
.header__fixed .header__contact-content {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--main);
}
.header__fixed .header__contact-email {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--main);
}
.header__fixed .header__nav {
  flex: 0 0 100%;
  border-radius: 0;
  background: transparent;
  padding: 0;
}
.header__fixed .header__contact-language {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: inherit;
  color: var(--main);
  cursor: pointer;
}
.header__fixed .header__contact-language::after {
  background: url("../img/language-arrow-color.svg") no-repeat center;
  background-size: contain;
}
.header__fixed .header__btn {
  width: 12.5rem;
  margin-top: 0.4rem;
}
.header__fixed .header__nav-list {
  align-items: baseline;
}

.header__contact-language:nth-child(2):after {
  display: none;
}
.header__contact-language--wrap {
  position: relative;
  font-size: 1rem;
}
.header__contact-language--zh {
  display: none;
  position: absolute;
  top: 1rem;
  cursor: pointer;
}
.header__contact-language--zh.active {
  display: block;
}
.header__contact-language--ru {
  cursor: pointer;
}
.header__contact-language.active::after {
  transform: rotate(180deg);
  top: 35%;
}

@media (max-width: 800px) {
  .header__fixed.active {
    display: none;
  }
  .header {
    display: none;
  }
  .header__mobile {
    display: block;
    padding: 0.375rem 0;
  }
  .header__mobile .header__wrapper {
    align-items: center;
  }
  .header__mobile .header__contact-wrapper {
    flex: 0 0 auto;
    position: relative;
  }
  .header__mobile .header__contact-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
    gap: 0px;
  }
  .header__mobile .header__contact-language {
    color: var(--white);
  }
  .header__mobile .header__contact-phone {
    font-size: 0.875rem;
  }
  .header__mobile .header__burger {
    width: 1.625rem;
    height: auto;
    cursor: pointer;
  }
  .header__mobile .header__logo {
    width: 10.3125rem;
    height: auto;
    padding-top: 0rem;
  }
  .header__mobile .header__contact-icon {
    width: 1rem;
    height: 1rem;
  }
}
.header__nav-mobile {
  position: absolute;
  right: -31.25rem;
  top: 0;
  z-index: 200;
  transition: all 0.5s ease;
}

.header__nav-mobile.active {
  right: 0;
  transition: all 0.5s ease;
}

.header__nav-mobile--wrapper.active {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  transition: all 0.5s ease;
}

.header__contact-language--wrap {
  width: 2.8rem;
}

.header__nav-mobile--content {
  border-radius: 0.625rem;
  width: 320px;
  background: var(--light);
  padding: 40px 50px;
}
.header__nav-mobile--content .header__nav-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 20px;
  margin: 0;
}
.header__nav-mobile--content .btn-light {
  height: 52px;
  font-size: 16px;
}
.header__nav-mobile--content .modal__close {
  width: 18px;
  height: 18px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.header__nav-mobile--content .header__contact-wrapper {
  margin-bottom: 2.5rem;
}
.header__nav-mobile--content .header__nav {
  margin-bottom: 40px;
  padding: 0;
  background: transparent;
  border-radius: 0;
}
.header__nav-mobile--content .header__contact-item {
  gap: 0.4625rem;
}
.header__nav-mobile--content .header__contact-item:first-child {
  margin-bottom: 0.875rem;
}
.header__nav-mobile--content .header__btn {
  width: 100%;
}
.header__nav-mobile--content .header__nav-item:nth-child(3)::after {
  background-position: right 0.242rem;
}
.header__nav-mobile--content .nav__link {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: var(--dark);
}
.header__nav-mobile--content .nav__link:hover {
  -webkit-text-stroke: 1px var(--main);
}
.header__nav-mobile--content .header__contact-location {
  flex-direction: column;
  align-items: start;
  gap: 14px;
  margin-bottom: 40px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--dark);
}
.header__nav-mobile--content .header__contact-item:first-child {
  margin-bottom: 0rem;
}
.header__nav-mobile--content .btn-light {
  width: 100%;
}
.header__nav-mobile--content .header__contact-time p {
  font-size: 14px;
}

.just-validate-error-label {
  display: none;
}

@media (max-width: 800px) {
  .header__contact-language--wrap {
    width: 2.7rem;
  }
  .header__nav-mobile--content .header__social {
    display: none;
  }
  .header__nav-mobile--content .header__contact-wrapper {
    display: none;
  }
}
@media (max-width: 550px) {
  .header__contact-language--wrap {
    width: 2.7rem;
  }
  .header__mobile {
    display: block;
    padding: 0.684375rem 0;
  }
  .header__mobile .header__social {
    display: none;
  }
  .header__mobile .header__contact-phone--img {
    display: inline-block;
    padding-right: 1.2rem;
    position: relative;
    cursor: pointer;
  }
  .header__mobile .header__contact-phone--img::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: inline-block;
    content: ""; /* Выводим плюс */
    background: url("../img/language-arrow.svg") no-repeat center;
    background-size: contain;
    width: 0.875rem;
    height: 0.5rem;
  }
  .header__mobile .header__contact-content {
    display: none;
    position: absolute;
    width: max-content;
    left: -6rem;
    top: 2.2rem;
  }
  .header__mobile .header__contact-phone {
    font-size: 0.875rem;
    line-height: 148%;
  }
  .header__mobile .header__logo {
    width: 156px;
    width: 9.75rem;
    height: 2rem;
  }
  .header__mobile .header__contact-language {
    font-size: 0.875rem;
  }
  .header__mobile .header__wrapper {
    gap: 0;
  }
  .header__mobile .header__burger {
    width: 24px;
    height: auto;
  }
  #contact-content.active {
    display: block;
  }
  #contact-phone.active::after {
    transform: rotate(180deg);
    top: 35%;
  }
}
@media (max-width: 550px) {
  .header__nav-mobile--content .header__nav-item {
    line-height: 40%;
  }
  .header__nav-mobile--content .header__nav-list {
    gap: 16px;
  }
  .header__nav-mobile--content .header__social {
    display: flex;
  }
  .header__nav-mobile--content .header__contact-wrapper {
    display: flex;
    margin-bottom: 0;
  }
  .header__nav-mobile--content .header__social {
    gap: 8px;
    align-items: start;
    justify-content: start;
    margin-bottom: 30px;
  }
  .header__nav-mobile--content .social-link {
    display: flex;
    width: 24px;
    height: 24px;
  }
  .header__nav-mobile--content .header__contact-content {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .header__nav-mobile--content .header__contact-phone {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--dark);
    gap: 2px;
  }
  .header__nav-mobile--content .header__contact-icon {
    width: 20px;
    height: 20px;
  }
  .header__nav-mobile--content .header__nav {
    margin-bottom: 30px;
  }
  .header__nav-mobile--content .modal__close {
    display: flex;
  }
  .header__nav-mobile--content .header__contact-content {
    margin-bottom: 20px;
  }
  .header__nav-mobile--content .header__contact-item {
    gap: 6px;
  }
  .header__nav-mobile--content .header__contact-address {
    font-size: 14px;
  }
  .header__nav-mobile--content .header__contact-location {
    gap: 8px;
    margin-bottom: 30px;
  }
  .header__nav-mobile--content .header__contact-email {
    font-size: 14px;
  }
  .header__nav-mobile--content .btn-light {
    border-radius: 10px;
  }
}
.main__section {
  position: relative;
  background: url("../img/bg-main.jpg") no-repeat center;
  background-size: cover;
  padding: 13.125rem 0 6.3125rem;
  border-radius: 0 0 0.625rem 0.625rem;
}
.main__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.main__wrapper {
  max-width: 46.75rem;
}
.main__title {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 4.125rem;
  line-height: 100%;
  color: var(--main);
  margin-bottom: 4.625rem;
}
.main__title span {
  font-weight: 800;
}
.main__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 3.75rem;
}
.main__item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 0 0 22.75rem;
  padding: 0.5rem 0.625rem;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.8);
}
.main__item-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}
.main__item-icon img {
  opacity: 0.8;
}
.main__item-text {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
}
.main__item-text span {
  font-weight: 700;
}
.main__btn {
  width: 22.75rem;
  background: var(--accent);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--white);
}
.main__btn:hover {
  box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
  background: #ff7919;
  transition: background 0.2s ease;
}
.main__circle {
  position: absolute;
  bottom: -3.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
}
.main__circle img {
  width: 6rem;
  height: 6rem;
}

@media (max-width: 800px) {
  .main__title {
    font-size: 3rem;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 2.4625rem;
  }
  .main__section {
    padding: 8.8125rem 0 3.93125rem;
    margin-bottom: 0;
  }
  .main__item {
    gap: 0.7099875rem;
    flex: 0 0 19.8375rem;
    padding: 0.5rem 0.625rem;
  }
  .main__item-text {
    font-size: 0.875rem;
  }
  .main__btn {
    width: 19.2875rem;
  }
  .main__content {
    margin-bottom: 2.275rem;
  }
  .main__circle img {
    width: 4.375rem;
    height: 4.375rem;
  }
  .main__circle {
    width: 5.375rem;
    height: 5.375rem;
    bottom: -2.75rem;
  }
}
@media (max-width: 550px) {
  .main__title {
    font-size: 1.845rem;
    line-height: 110%;
    letter-spacing: -0.02em;
    margin-bottom: 1.25rem;
    font-weight: 500;
    text-transform: none;
  }
  .main__section {
    padding: 4.48125rem 0 3.4593125rem;
    margin-bottom: 0;
  }
  .main__section.active {
    padding-top: 7.8948125rem;
  }
  .main__item {
    gap: 0.7099875rem;
    flex: 0 0 100%;
    padding: 0.35rem 0.625rem;
  }
  .main__item-text {
    font-size: 0.75rem;
  }
  .main__content {
    gap: 0.625rem;
    margin-bottom: 1.8275rem;
  }
  .main__btn {
    width: 100%;
  }
  .main__circle img {
    width: 3rem;
    height: 3rem;
  }
  .main__circle {
    width: 3.75rem;
    height: 3.75rem;
    bottom: -1.75rem;
  }
}
.services {
  padding-bottom: 0;
}
.services__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.9375rem;
}
.services__item {
  position: relative;
  border-radius: 0.625rem;
  padding: 2.4375rem 1.937rem 2.75rem 1.937rem;
  background: var(--main);
}
.services__item:first-child {
  flex: 0 0 59%;
  background: var(--accent);
}
.services__item:first-child .services__item-content {
  max-width: 23.8125rem;
}
.services__item:first-child .services__item-img {
  width: 21.3125rem;
  height: auto;
  bottom: -2rem;
}
.services__item:nth-child(2) {
  flex: 0 0 38%;
}
.services__item:nth-child(2) .services__item-content {
  max-width: 12.5rem;
}
.services__item:nth-child(2) .services__item-img {
  width: 17.25rem;
  height: auto;
}
.services__item:nth-child(3) {
  flex: 0 0 38%;
  padding-bottom: 5.6rem;
}
.services__item:nth-child(3) .services__item-content {
  max-width: 13.5rem;
}
.services__item:nth-child(3) .services__item-img {
  width: 24.125rem;
  height: auto;
}
.services__item:last-child {
  flex: 0 0 59%;
}
.services__item:last-child .services__item-content {
  max-width: 25.8125rem;
}
.services__item:last-child .services__item-img {
  width: 25.875rem;
  height: auto;
  bottom: 0rem;
}
.services__item-img {
  position: absolute;
  right: 0;
}
.services__item-title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  color: var(--white);
  margin-bottom: 1.25rem;
}
.services__item-text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--white);
}

@media (max-width: 800px) {
  .services__item-title {
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
    font-weight: 500;
  }
  .services__item-text {
    font-size: 0.75rem;
  }
  .services__item {
    padding: 1.25rem;
    overflow: hidden;
  }
  .services__item:first-child {
    flex: 0 0 57%;
  }
  .services__item:first-child .services__item-img {
    width: 13.75rem;
    height: auto;
    bottom: -1.5rem;
    right: -1rem;
  }
  .services__item:nth-child(2) {
    flex: 0 0 40%;
  }
  .services__item:nth-child(2) .services__item-img {
    width: 12.375rem;
    bottom: -0.5rem;
  }
  .services__item:nth-child(3) {
    padding-bottom: 2.46rem;
    flex: 0 0 40%;
  }
  .services__item:nth-child(3) .services__item-img {
    width: 15.9375rem;
    bottom: -1.5rem;
  }
  .services__item:last-child {
    flex: 0 0 57%;
  }
  .services__item:last-child .services__item-img {
    width: 16.375rem;
    height: auto;
    bottom: -0.2rem;
  }
  .services__item:last-child .services__item-title {
    width: 80%;
  }
  .services__item-text {
    width: 63%;
  }
  .services__wrapper {
    gap: 1.1875rem;
  }
}
@media (max-width: 550px) {
  .services__item {
    flex: 0 0 100% !important;
    padding: 1.00625rem 0.5rem 0.6rem 0.5rem;
    height: 11.575rem;
  }
  .services__item-title {
    margin-bottom: 0.2875rem;
    line-height: 110%;
  }
  .services__wrapper {
    gap: 0.74661875rem;
  }
  .services__item:first-child .services__item-img {
    width: 8.4375rem;
    height: auto;
    bottom: -1.05rem;
    right: 0rem;
  }
  .services__item:first-child .services__item-text {
    width: 81%;
  }
  .services__item:nth-child(2) .services__item-img {
    width: 11.1875rem;
    bottom: -0.5rem;
  }
  .services__item:nth-child(2) .services__item-text {
    width: 87%;
  }
  .services__item:nth-child(3) .services__item-text {
    width: 66%;
  }
  .services__item:last-child .services__item-img {
    width: 12.1875rem;
    height: auto;
    bottom: -0.2rem;
  }
  .services__item:last-child .services__item-title {
    width: 100%;
  }
  .services__item:last-child .services__item-text {
    width: 67%;
  }
}
.advantages__section {
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
}
.advantages__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2.7875rem 1.5875rem;
}
.advantages__main-title {
  margin-bottom: 4.4rem;
}
.advantages__item {
  position: relative;
  flex: 0 0 49%;
}
.advantages__img {
  position: absolute;
  right: 1.25rem;
  top: -1.49375rem;
  width: 10rem;
  height: 5.9375rem;
}
.advantages__img img {
  object-fit: contain;
}
.advantages__title {
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  color: var(--main);
  padding-bottom: 1.5625rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--accent);
}
.advantages__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--dark);
}

@media (max-width: 800px) {
  .advantages {
    padding-top: 3.75rem;
  }
  .advantages__main-title {
    margin-bottom: 2.5rem;
  }
  .advantages__title {
    font-size: 1.25rem;
    margin-bottom: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .advantages__text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .advantages__img {
    height: 2.9375rem;
    width: 4.8rem;
    right: 0.525rem;
    top: 0.050625rem;
  }
  .advantages__wrapper {
    gap: 1.47875rem 0.5875rem;
  }
  .advantages__section {
    padding-bottom: 2.525rem;
    padding-top: 3.75rem;
  }
}
@media (max-width: 550px) {
  .advantages__section {
    padding: 2.5rem 0;
    padding-bottom: 1rem;
  }
  .advantages__item {
    flex: 0 0 100%;
  }
  .advantages__title {
    font-size: 1rem;
    line-height: 110%;
    margin-bottom: 0.375rem;
    padding-bottom: 0.5625rem;
  }
  .advantages__main-title {
    margin-bottom: 1.675rem;
  }
  .advantages__text {
    font-size: 0.75rem;
    line-height: 130%;
  }
  .advantages__img {
    height: 2.249375rem;
    width: 4rem;
    right: 0.0525rem;
    top: -0.250625rem;
  }
}
.about__section {
  position: relative;
  margin-top: 1.5rem;
  padding-top: 5rem;
  padding-bottom: 5.2rem;
  margin-bottom: 0.7rem;
}
.about__section-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.about__title {
  color: var(--white);
}
.about__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.5625rem;
}
.about__item:first-child {
  flex: 0 0 26.5625rem;
}
.about__item:last-child .about__item-text {
  padding-left: 1rem;
  width: 95%;
}
.about__item-text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.1875rem;
  line-height: 130%;
  color: var(--white);
}
.about__item-text--bg {
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.2);
  padding: 1.25rem;
  margin-bottom: 2.0875rem;
}
.about__item-img {
  border-radius: 0.625rem;
  width: 40.375rem;
  height: auto;
  margin-top: 2.053125rem;
}
.about__item-wrap .about__item-text:not(:last-child) {
  margin-bottom: 0.9625rem;
}

@media (max-width: 800px) {
  .about__section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .about__item-text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .about__item-wrap .about__item-text:not(:last-child) {
    margin-bottom: 0.625rem;
  }
  .about__item-text--bg {
    padding: 1rem;
    margin-bottom: 1.25rem;
  }
  .about__item-img {
    width: 23.875rem;
    height: 13.6875rem;
    margin-top: 1.25rem;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  .about__item:first-child {
    flex: 0 0 20.3834rem;
  }
  .about__wrapper {
    gap: 1.0015625rem;
  }
  .about__item:last-child .about__item-text {
    padding-left: 0rem;
    width: 100%;
  }
  .about__section-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  }
}
@media (max-width: 550px) {
  .about__section {
    padding-top: 3.7rem;
    padding-bottom: 2.162rem;
  }
  .about__wrapper {
    flex-direction: column;
  }
  .about__item:first-child {
    flex: 0 0 100%;
  }
  .about__item {
    flex: 0 0 100%;
  }
  .about__item-img {
    width: 100%;
    height: 12.5rem;
    margin-top: 0rem;
    margin-bottom: 0.625rem;
  }
  .about__item-text {
    font-size: 0.75rem;
  }
  .about__item-text--bg {
    padding: 0.6rem;
    margin-bottom: 0.625rem;
  }
  .about__item:last-child {
    display: flex;
    flex-direction: column;
  }
  .about__item:last-child .about__item-img {
    order: 1;
  }
  .about__item:last-child .about__item-text {
    order: 2;
  }
}
.certificates__section {
  padding-bottom: 11.5875rem;
}
.certificates__item {
  height: 26.625rem;
  width: auto;
  border-radius: 0.625rem;
  overflow: hidden;
}
.certificates__wrap {
  position: relative;
}
.certificates__btns {
  display: flex;
  gap: 1.3125rem;
}
.certificates__btn {
  position: relative;
  border-radius: 0.625rem;
  width: 3rem;
  height: 3rem;
  background: rgba(42, 64, 123, 0.05);
}
.certificates__btn::after {
  content: "";
  display: inline-block;
  width: 1.3125rem;
  height: 1rem;
}
.certificates__btn-prev::after {
  background: url("../img/btn-arrow-prev.svg") no-repeat center;
  background-size: contain;
}
.certificates__btn-next::after {
  background: url("../img/btn-arrow-next.svg") no-repeat center;
  background-size: contain;
}
.certificates__btn-wrapper {
  position: absolute;
  bottom: -5.5625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.certificates__btn-wrapper.desactive {
  display: none;
}
.certificates__pagination-wrapper {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.certificates__pagination-wrapper p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--dark);
}
.certificates__pagination {
  width: auto !important;
}

.certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}

.swiper-pagination-current {
  color: var(--accent);
}

.fancybox-image, .fancybox-spaceball {
  border-radius: 1rem;
}

@media (max-width: 800px) {
  .certificates__section {
    padding-bottom: 8.0875rem;
    padding-top: 2.2rem;
  }
  .certificates__item {
    height: 19.8625rem;
  }
  .certificates__btn-wrapper {
    position: absolute;
    bottom: -4.35625rem;
  }
  .certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
  }
  .certificates__pagination-wrapper p {
    font-size: 0.75rem;
  }
  .certificates__pagination-wrapper {
    gap: 1rem;
  }
}
@media (max-width: 550px) {
  .certificates__section {
    padding-bottom: 5.470875rem;
    padding-top: 0.42rem;
  }
  .certificates__item {
    height: 23.8375rem;
  }
  .certificates__slider {
    width: 80%;
  }
  .certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
    flex: 0 0 3rem;
  }
  .certificates__pagination-wrapper p {
    font-size: 0.625rem;
    padding-top: 0.1875rem;
  }
  .certificates__pagination-wrapper {
    gap: 0.2625rem;
  }
  .certificates__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(42, 64, 123, 0.05);
  }
  .certificates__btn::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 0.75rem;
  }
  .certificates__btn-wrapper {
    position: absolute;
    bottom: -3.0535625rem;
  }
  .certificates__btns {
    gap: 0.875rem;
  }
}
@media (max-width: 400px) {
  .certificates__slider {
    width: 93%;
  }
}
.consultation__section {
  background: var(--main);
}
.consultation__title {
  color: var(--white);
  margin-bottom: 1.525rem;
}
.consultation__subtitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--white);
  margin-bottom: 3.75rem;
  text-align: center;
}
.consultation__wrapper {
  padding: 0 1.875rem;
}

.form__content {
  display: flex;
  align-items: end;
  gap: 1.25rem;
  justify-content: space-between;
}
.form__item-label {
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  color: var(--white);
}
.form__item-input {
  display: block;
  width: 100%;
  padding: 0 1.1875rem;
  width: 22.625rem;
  height: 3.25rem;
  outline: none;
  border: 1px solid var(--main);
  border-radius: 0.625rem;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}
.form__item-btn {
  border-radius: 0.625rem;
  width: 16.9375rem;
  height: 3.25rem;
  background: var(--accent);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--white);
}
.form__item-btn:hover {
  box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
  background: #ff7919;
  transition: background 0.2s ease;
}

input.just-validate-error-field {
  border: 1px solid #f00;
}

.just-validate-error-label {
  position: absolute;
  color: rgb(212, 26, 26);
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.consultation__label-checkbox {
  display: flex;
  align-items: center;
  margin-top: 0.875rem;
  font-weight: 300;
}
.consultation__label-checkbox p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--white);
}
.consultation__checkbox {
  appearance: none;
}
.consultation__checkbox-link {
  text-decoration: underline;
}
.consultation__label-checkbox--mobile {
  display: none;
}

.consultation__label-checkbox span {
  flex-shrink: 0;
  margin-right: 0.625rem;
  display: inline-block;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--white);
  border-radius: 0.3125rem;
}

.consultation__label-checkbox input:checked + span {
  background: url("../img/form-union.svg") center no-repeat;
  background-size: contain;
  background-size: auto;
}

@media (max-width: 800px) {
  .consultation__subtitle {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 2.475rem;
  }
  .consultation__wrapper {
    padding: 0;
  }
  .consultation__form .form__content {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0.825rem;
  }
  .consultation__form .form__wrap {
    max-width: 22.625rem;
    width: 100%;
    margin: 0 auto;
  }
  .consultation__form .form__item-input {
    width: 100%;
    margin-top: 0.2rem;
  }
  .consultation__form .form__item-label {
    width: 100%;
    font-size: 0.875rem;
    line-height: 140%;
  }
  .consultation__form .form__item-btn {
    width: 100%;
    margin-top: 1.5rem;
  }
  .consultation__form .consultation__label-checkbox {
    display: none;
  }
  .consultation__form .consultation__label-checkbox--mobile {
    display: flex;
    margin-top: 0;
  }
}
@media (max-width: 550px) {
  .consultation__subtitle {
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 1.2475rem;
  }
  .consultation__title {
    margin-bottom: 0.3525rem;
  }
  .consultation__form .form__item-label {
    font-size: 0.75rem;
    line-height: 130%;
  }
  .consultation__form .form__item-input {
    height: 2.625rem;
    font-weight: 300;
    font-size: 0.75rem;
    padding: 0 0.625rem;
    margin-top: 0.32rem;
  }
  .consultation__form .consultation__label-checkbox p {
    font-weight: 300;
    font-size: 0.75rem;
  }
  .consultation__form .form__item-btn {
    height: 2.625rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 110%;
    margin-top: 0.585rem;
  }
}
.cargo__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.875rem;
}
.cargo__item {
  position: relative;
  border-radius: 0.625rem;
  background: var(--accent);
  color: var(--white);
  padding: 2.4375rem 1.9375rem;
  height: 16.25rem;
}
.cargo__item:first-child {
  flex: 0 0 40.3125rem;
}
.cargo__item:first-child .cargo__img {
  width: 18.9375rem;
  height: auto;
  right: 1.2rem;
  bottom: 0.5rem;
}
.cargo__item:nth-child(2) {
  flex: 0 0 26.25rem;
}
.cargo__item:nth-child(2) .cargo__img {
  width: 19.4375rem;
  height: auto;
  bottom: -1.4rem;
}
.cargo__item:last-child {
  flex: 0 0 100%;
  background: var(--main);
}
.cargo__item:last-child .cargo__img {
  width: 27.3125rem;
  height: auto;
  bottom: -4rem;
  right: 4.5rem;
}
.cargo__item:last-child .cargo__content {
  max-width: 40%;
}
.cargo__img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cargo__content-title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  color: var(--white);
  margin-bottom: 1.25rem;
}
.cargo__content-list {
  margin-left: 1.59375rem;
}
.cargo__content-item {
  list-style: disc;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--white);
}

@media (max-width: 800px) {
  .cargo__content-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
  }
  .cargo__content-item {
    font-size: 0.754rem;
  }
  .cargo__item:first-child {
    flex: 0 0 25.673125rem;
  }
  .cargo__item:first-child .cargo__img {
    width: 14.3125rem;
    right: 0.52rem;
    bottom: 0.005rem;
  }
  .cargo__item:nth-child(2) {
    flex: 0 0 17.825rem;
  }
  .cargo__item:nth-child(2) .cargo__img {
    width: 13.4375rem;
  }
  .cargo__item:last-child .cargo__img {
    width: 18.8125rem;
    bottom: -3rem;
    right: 3.5rem;
  }
  .cargo__wrapper {
    gap: 1.25rem 0.625rem;
  }
  .cargo__item {
    padding: 1.114375rem 1.09375rem;
    height: 10.725rem;
  }
}
@media (max-width: 550px) {
  .cargo__item {
    flex: 0 0 100% !important;
    height: 9.05101875rem;
  }
  .cargo__item:first-child .cargo__img {
    width: 9.375rem;
    right: -0.40052rem;
    bottom: 0.005rem;
  }
  .cargo__item:nth-child(2) .cargo__img {
    width: 11.1875rem;
    bottom: -0.4rem;
  }
  .cargo__item:last-child .cargo__img {
    width: 9.6875rem;
    bottom: 0.6rem;
    right: -0.85rem;
  }
  .cargo__item {
    padding: 1.114375rem 0.609375rem;
  }
  .cargo__content-list {
    margin-left: 1.259375rem;
  }
  .cargo__content-title {
    margin-bottom: 0.4875rem;
    width: 100%;
  }
  .cargo__wrapper {
    gap: 0.925rem;
  }
  .cargo__item:last-child .cargo__content {
    max-width: 65%;
  }
}
.portfolio__section {
  padding-bottom: 11.875rem;
  background: var(--light);
}
.portfolio__wrap {
  position: relative;
}
.portfolio__btns {
  display: flex;
  gap: 1.3125rem;
}
.portfolio__btn {
  position: relative;
  border-radius: 0.625rem;
  width: 3rem;
  height: 3rem;
  background: rgba(42, 64, 123, 0.05);
}
.portfolio__btn::after {
  content: "";
  display: inline-block;
  width: 1.3125rem;
  height: 1rem;
}
.portfolio__btn-prev::after {
  background: url("../img/btn-arrow-prev.svg") no-repeat center;
  background-size: contain;
}
.portfolio__btn-next::after {
  background: url("../img/btn-arrow-next.svg") no-repeat center;
  background-size: contain;
}
.portfolio__btn-wrapper {
  position: absolute;
  bottom: -5.5625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolio__pagination-wrapper {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.portfolio__pagination-wrapper p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--dark);
}
.portfolio__pagination {
  width: auto !important;
}

.portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}

.swiper-pagination-current {
  color: var(--accent);
}

.portfolio__slide-wrap {
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;
}
.portfolio__slide-item--content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.25rem;
}
.portfolio__slide-title {
  margin-bottom: 1.25rem;
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  color: var(--dark);
}
.portfolio__slide-text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--dark);
}
.portfolio__slide-subtitle {
  margin-bottom: 1.25rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: var(--dark);
}
.portfolio__slide-btn {
  width: 21.25rem;
  font-size: 1.25rem;
}
.portfolio__slide-btn--mobile {
  display: none;
}

.portfolio__slider-main {
  min-height: 26.25rem;
}

.portfolio__slide-item {
  width: 49%;
}

.image-mini-slider {
  margin-top: 0.625rem;
}
.image-mini-slider .swiper-slide {
  overflow: hidden;
  border-radius: 0.625rem;
}

.swiper-slide.portfolio__slider-main--slide {
  overflow: hidden;
  border-radius: 0.625rem;
  height: 26.25rem;
}

.image-mini-slider {
  position: relative;
}
.image-mini-slider .swiper-button-prev:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url("../img/portfolio-left.svg") no-repeat center;
  background-size: contain;
}
.image-mini-slider .swiper-button-next:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url("../img/portfolio-right.svg") no-repeat center;
  background-size: contain;
}
.image-mini-slider .swiper-button-next, .image-mini-slider .swiper-button-prev {
  top: auto;
  bottom: 3.5rem;
  width: 1.25rem;
  height: 1.25rem;
}
.image-mini-slider .swiper-button-prev--second {
  left: 10px;
}
.image-mini-slider .swiper-button-prev--second:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url("../img/portfolio-left.svg") no-repeat center;
  background-size: contain;
}
.image-mini-slider .swiper-button-next--second {
  right: 10px;
}
.image-mini-slider .swiper-button-next--second:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background: url("../img/portfolio-right.svg") no-repeat center;
  background-size: contain;
}
.image-mini-slider .swiper-button-next--second, .image-mini-slider .swiper-button-prev--second {
  position: absolute;
  top: auto;
  bottom: 3.5rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  z-index: 100;
}
.image-mini-slider .swiper-slide {
  height: 8rem;
}

.image-mini-slider .swiper-slide {
  opacity: 0.4;
}

.image-mini-slider .swiper-slide-active {
  opacity: 1;
}

@media (max-width: 800px) {
  .portfolio__slide-title {
    font-size: 1.25rem;
    margin-bottom: 0.875rem;
  }
  .portfolio__slide-text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .portfolio__slide-subtitle {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.625rem;
  }
  .portfolio__slide-btn {
    width: 21.25rem;
    font-size: 1.25rem;
  }
  .portfolio__slider-main {
    min-height: 16.8rem;
  }
  .image-mini-slider {
    margin-top: 0.52425rem;
    height: 5.2rem;
  }
  .image-mini-slider .swiper-slide {
    height: 5.2rem;
  }
  .portfolio__slide-item {
    min-height: auto;
    width: 49%;
  }
  .image-mini-slider .swiper-button-next, .image-mini-slider .swiper-button-prev,
  .image-mini-slider .swiper-button-next--second, .image-mini-slider .swiper-button-prev--second {
    bottom: 1.995rem;
  }
  .portfolio__slide-wrap {
    gap: 1rem;
  }
  .portfolio__btn-wrapper {
    bottom: -4.25625rem;
  }
  .portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
  }
  .portfolio__pagination-wrapper p {
    font-size: 0.75rem;
  }
  .portfolio__pagination-wrapper {
    gap: 1rem;
  }
  .portfolio__section {
    padding-bottom: 7.99875rem;
  }
  .swiper-slide.portfolio__slider-main--slide {
    height: 16.875rem;
  }
}
@media (max-width: 550px) {
  .portfolio__slide-wrap {
    flex-direction: column;
    align-items: start;
    gap: 1.556875rem;
  }
  .portfolio__slide-item {
    min-height: auto;
    width: 100%;
  }
  .portfolio__title {
    margin-bottom: 2.375rem;
  }
  .portfolio__slider-main {
    min-height: auto;
  }
  .swiper-slide.portfolio__slider-main--slide {
    height: 13.8125rem;
  }
  .image-mini-slider {
    height: auto;
  }
  .image-mini-slider .swiper-slide {
    height: 4.52rem;
  }
  .portfolio__slide-title {
    font-size: 1.25rem;
    margin-bottom: 0.5875rem;
  }
  .portfolio__slide-text {
    font-size: 0.75rem;
  }
  .portfolio__slide-btn {
    display: none;
  }
  .portfolio__slide-btn--mobile {
    display: block;
    width: 100%;
    font-size: 1.25rem;
    font-size: 1rem;
    height: 2.625rem;
  }
  .portfolio__slider {
    margin-bottom: 4.6rem;
  }
  .portfolio__btn-wrapper {
    bottom: 3.8rem;
  }
  .portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
    flex: 0 0 3rem;
  }
  .portfolio__pagination-wrapper p {
    font-size: 0.625rem;
    padding-top: 0.1875rem;
  }
  .portfolio__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(42, 64, 123, 0.05);
  }
  .portfolio__btn::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 0.75rem;
  }
  .portfolio__btns {
    gap: 0.875rem;
  }
  .portfolio__pagination-wrapper {
    gap: 0.2625rem;
  }
  .portfolio__slide-subtitle {
    font-size: 0.875rem;
    margin-bottom: 0.375rem;
  }
  .portfolio__slide-item--content {
    gap: 1rem;
  }
  .portfolio__section {
    padding-bottom: 2.399875rem;
  }
  .image-mini-slider .swiper-button-next, .image-mini-slider .swiper-button-prev,
  .image-mini-slider .swiper-button-next--second, .image-mini-slider .swiper-button-prev--second {
    bottom: 1.52995rem;
  }
}
.team__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.875rem;
}
.team__item {
  overflow: hidden;
  border-radius: 0.625rem;
  flex: 0 0 21.5625rem;
  height: 30.375rem;
  box-shadow: 0 0 25px 0 rgba(40, 65, 123, 0.1);
}
.team__item-content {
  padding: 1.25rem 2.3125rem;
}
.team__item-img {
  width: 100%;
  height: 20.5rem;
}
.team__item-title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: var(--main);
  margin-bottom: 0.875rem;
}
.team__item-email {
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
  margin-bottom: 0.625rem;
  gap: 0.625rem;
}
.team__item-email span {
  font-weight: 700;
}
.team__item-position {
  display: flex;
  align-items: start;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
  gap: 0.625rem;
}
.team__item-position span:first-child {
  font-weight: 700;
}
.team.active {
  display: block;
}
.team.desactive {
  display: none;
}

@media (max-width: 800px) {
  .team__item {
    flex: 0 0 13.9990625rem;
    min-height: 20.75rem;
    height: auto;
  }
  .team__item-img {
    width: 100%;
    height: 12.65rem;
  }
  .team__item-title {
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
    font-weight: 500;
  }
  .team__item-email {
    font-size: 0.875rem;
    line-height: 140%;
    margin-bottom: 0.5rem;
  }
  .team__item-position {
    font-size: 0.75rem;
    line-height: 130%;
    gap: 0.375rem;
  }
  .team__item-position span:first-child {
    font-size: 0.875rem;
  }
  .team__item-content {
    padding: 0.875rem 0.375rem 0 0.375rem;
  }
  .team__wrapper {
    gap: 1.25rem;
  }
  .team__item-email {
    gap: 0.375rem;
  }
}
@media (max-width: 550px) {
  .team__section {
    display: none;
  }
}
.team-slider__item {
  overflow: hidden;
  border-radius: 0.625rem;
  height: 30.375rem;
  box-shadow: 0 0 25px 0 rgba(40, 65, 123, 0.1);
  margin-bottom: 0.8125rem;
}
.team-slider__item-content {
  padding: 1.25rem 1.503125rem;
}
.team-slider__item-img {
  width: 100%;
  height: 20.5rem;
}
.team-slider__item-title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: var(--main);
  margin-bottom: 0.875rem;
}
.team-slider__item-email {
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
  margin-bottom: 0.625rem;
  gap: 0.625rem;
}
.team-slider__item-email span {
  font-weight: 700;
}
.team-slider__item-position {
  display: flex;
  align-items: start;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
  gap: 0.625rem;
}
.team-slider__item-position span:first-child {
  font-weight: 700;
}

.team-slider__section {
  padding-bottom: 11.875rem;
}
.team-slider__container {
  padding: 0 7rem;
}
.team-slider__wrap {
  position: relative;
  width: 100%;
}
.team-slider__btns {
  display: flex;
  gap: 1.3125rem;
}
.team-slider__btn {
  position: relative;
  border-radius: 0.625rem;
  width: 3rem;
  height: 3rem;
  background: rgba(42, 64, 123, 0.05);
}
.team-slider__btn::after {
  content: "";
  display: inline-block;
  width: 1.3125rem;
  height: 1rem;
}
.team-slider__btn-prev::after {
  background: url("../img/btn-arrow-prev.svg") no-repeat center;
  background-size: contain;
}
.team-slider__btn-next::after {
  background: url("../img/btn-arrow-next.svg") no-repeat center;
  background-size: contain;
}
.team-slider__btn-wrapper {
  position: absolute;
  bottom: -4.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.team-slider__pagination-wrapper {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.team-slider__pagination-wrapper p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--dark);
}
.team-slider__pagination {
  width: auto !important;
}
.team-slider.active {
  display: block;
}
.team-slider.desactive {
  display: none;
}

.team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}

.swiper-pagination-current {
  color: var(--accent);
}

@media (max-width: 800px) {
  .team-slider__item {
    min-height: 20.75rem;
    height: auto;
  }
  .team-slider__item-img {
    width: 100%;
    height: 12.65rem;
  }
  .team-slider__item-title {
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
    font-weight: 500;
  }
  .team-slider__item-email {
    font-size: 0.875rem;
    line-height: 140%;
    margin-bottom: 0.5rem;
  }
  .team-slider__item-position {
    font-size: 0.75rem;
    line-height: 130%;
    gap: 0.375rem;
  }
  .team-slider__item-position span:first-child {
    font-size: 0.875rem;
  }
  .team-slider__item-content {
    padding: 0.875rem 0.375rem 0 0.375rem;
  }
  .team-slider__item-email {
    gap: 0.375rem;
  }
  .team-slider__container {
    padding: 0rem;
  }
  .team-slider__btn-wrapper {
    position: absolute;
    bottom: -4.35625rem;
  }
  .team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
  }
  .team-slider__pagination-wrapper p {
    font-size: 0.75rem;
  }
  .team-slider__pagination-wrapper {
    gap: 1rem;
  }
  .team-slider__section {
    padding-bottom: 8.52875rem;
  }
}
@media (max-width: 550px) {
  .team-slider__item {
    min-height: 23.8125rem;
  }
  .team-slider__item-img {
    width: 100%;
    height: 15.765rem;
  }
  .team-slider__item-content {
    padding: 0.5875rem 0.375rem 0 1.7375rem;
  }
  .team-slider__item-email {
    gap: 0.5375rem;
  }
  .team-slider__item-position {
    gap: 0.5375rem;
  }
  .team-slider__item-position {
    line-height: 140%;
    font-size: 0.875rem;
  }
  .team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
    flex: 0 0 3rem;
  }
  .team-slider__pagination-wrapper p {
    font-size: 0.625rem;
    padding-top: 0.1875rem;
  }
  .team-slider__pagination-wrapper {
    gap: 0.2625rem;
  }
  .team-slider__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(42, 64, 123, 0.05);
  }
  .team-slider__btn::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 0.75rem;
  }
  .team-slider__btn-wrapper {
    position: absolute;
    bottom: -2.3535625rem;
  }
  .team-slider__btns {
    gap: 0.875rem;
  }
  .team-slider__section {
    padding-bottom: 5.352875rem;
  }
}
.values__section {
  background: var(--light);
  padding-bottom: 0;
}
.values__wrapper {
  display: flex;
  justify-content: center;
  gap: 1.875rem;
  margin-left: 6.25rem;
}
.values__item:first-child {
  flex: 0 0 33.25rem;
}
.values__item:last-child {
  flex: 0 0 40.3125rem;
}
.values__item:last-child .values__item-text {
  width: 80%;
}
.values__item-text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--dark);
}
.values__item-text:not(:last-child) {
  margin-bottom: 1.25rem;
}
.values__item-img {
  border-radius: 0.625rem;
  width: 40.03125rem;
  height: auto;
}

@media (max-width: 800px) {
  .values__item-text {
    font-size: 0.875rem;
    line-height: 140%;
    margin-bottom: 1.25rem;
  }
  .values__item-img {
    width: 22.125rem;
    height: 21.0625rem;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  .values__wrapper {
    margin-left: 0;
  }
  .values__item:first-child {
    flex: 0 0 48%;
  }
  .values__item:last-child {
    flex: 0 0 50%;
  }
  .values__item:last-child .values__item-text {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .values__wrapper {
    flex-direction: column;
    gap: 0px;
  }
  .values__item:first-child {
    flex: 0 0 100%;
  }
  .values__item:last-child {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: -0.7875rem;
  }
  .values__item:last-child .values__item-img {
    order: 1;
  }
  .values__item:last-child .values__item-text {
    order: 2;
  }
  .values__item-img {
    width: 100%;
    height: 12.5rem;
  }
  .values__item-text {
    font-size: 0.75rem;
    line-height: 130%;
  }
  .values__item-text:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .values__section {
    padding-bottom: 1.7rem;
  }
}
.contact__wrapper {
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0 0 1.25rem 0 rgba(40, 65, 123, 0.1);
  margin-bottom: 3.75rem;
}
.contact__btns {
  display: flex;
  align-items: center;
}
.contact__btn {
  width: 50%;
  padding: 1.4375rem 0;
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--white);
  background: rgba(42, 64, 123, 0.3);
}
.contact__btn.active {
  background: var(--main);
}
.contact__content {
  display: none;
  padding: 2.5rem 7.3125rem;
}
.contact__content.active {
  display: block;
}
.contact__content-item {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.contact__content-item:not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--light);
}
.contact__content-title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: var(--dark);
}
.contact__content-text {
  flex: 0 0 20.04375rem;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--dark);
}
.contact__content-social {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.contact__content-item--social {
  align-items: center;
}
.contact__content-phone a {
  display: block;
}
.contact__content-phone a:not(:last-child) {
  margin-bottom: 0.84375rem;
}

.contact__form {
  width: 50.25rem;
  margin: 0 auto;
}
.contact__form-content {
  border-radius: 0.625rem;
  padding: 2.5rem 1.875rem;
  background: var(--main);
}
.contact__form-title {
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  text-align: center;
  color: var(--main);
  margin-bottom: 0.5625rem;
}
.contact__form-subtitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: var(--dark);
  margin-bottom: 1.875rem;
  text-align: center;
}
.contact__form-textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--main);
  border-radius: 0.625rem;
  height: 4.8125rem;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
  padding: 1.1875rem;
  resize: none;
}
.contact__form .form__item-input {
  width: 100%;
}
.contact__form .form__wrap {
  display: flex;
  gap: 1.25rem;
}
.contact__form .form__content {
  flex-direction: column;
  gap: 1.25rem;
  flex: 0 0 22.625rem;
}
.contact__form .form__item-label {
  width: 100%;
}
.contact__form .form__item-btn {
  width: 100%;
}
.contact__form .just-validate-error-label {
  bottom: -1.3125rem;
  left: 2.25rem;
}

.contact__label-checkbox--mobile {
  display: none;
}

@media (max-width: 800px) {
  .contact__btn {
    width: 50%;
    padding: 0.74375rem 0;
  }
  .contact__content-title {
    font-weight: 500;
    font-size: 1.25rem;
  }
  .contact__content-text {
    font-size: 1rem;
    flex: 0 0 16.050438rem;
  }
  .contact__content {
    display: none;
    padding: 2.005rem 1.83125rem;
    padding-right: 1.25rem;
    padding-bottom: 2.3rem;
  }
  .contact__content-item:not(:last-child) {
    padding-bottom: 0.78125rem;
    margin-bottom: 0.78125rem;
  }
  .contact__wrapper {
    margin-bottom: 2.575rem;
  }
  .contact__form-subtitle {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  .contact__form {
    max-width: 30.125rem;
  }
  .contact__form-content {
    border-radius: 0.625rem;
    padding: 1.85rem 3.875rem;
  }
  .contact__form-content .form__wrap {
    display: block;
  }
  .contact__form-content .form__item-label {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .contact__form-content .form__item-input {
    margin-top: 0.2rem;
  }
  .contact__form-content .form__content {
    gap: 1.00025rem;
    align-items: start;
  }
  .contact__form-content .form__item-label--textarea {
    margin-top: 0.8rem;
  }
  .contact__form-content .contact__form-textarea {
    height: 5.8125rem;
  }
  .contact__form-content .consultation__label-checkbox {
    display: none;
  }
  .contact__form-content .contact__label-checkbox--mobile {
    margin-top: -0.3125rem;
    display: flex;
  }
  .contact__form-content .form__item-btn {
    margin-top: 1.4375rem;
  }
}
@media (max-width: 550px) {
  .contact__btn {
    padding: 0.74375rem 0;
    font-size: 1rem;
    line-height: 100%;
  }
  .contact__content-title {
    font-weight: 700;
    font-size: 1rem;
  }
  .contact__content-text {
    font-size: 0.875rem;
    line-height: 140%;
    flex: 0 0 100%;
  }
  .contact__content-item {
    flex-direction: column;
    gap: 0.375rem;
  }
  .contact__content {
    padding: 1.25rem 2.4375rem;
    padding-right: 2rem;
  }
  .contact__content-item:not(:last-child) {
    margin-bottom: 0.378125rem;
  }
  .contact__content-phone a:not(:last-child) {
    margin-bottom: 0.2384375rem;
  }
  .contact__content-item--social {
    align-items: start;
  }
  .contact__form {
    max-width: auto;
    width: 100%;
  }
  .contact__form-title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    margin-bottom: 0.2375rem;
  }
  .contact__form-subtitle {
    font-size: 0.999rem;
    margin-bottom: 1.25rem;
    font-weight: 400;
  }
  .contact__form-content {
    border-radius: 0.625rem;
    padding: 1.185rem 0.5875rem;
  }
  .contact__form-content .form__item-label {
    font-size: 0.75rem;
  }
  .contact__form-content .form__item-input {
    margin-top: 0.2rem;
    height: 2.625rem;
    font-size: 0.75rem;
    padding: 0 0.625rem;
  }
  .contact__form-content .contact__form-textarea {
    margin-top: 0.3125rem;
    height: 5rem;
    font-size: 0.75rem;
    padding: 0.625rem;
  }
  .contact__form-content .form__content {
    gap: 0.800025rem;
  }
  .contact__form-content .consultation__label-checkbox p {
    font-size: 0.75rem;
  }
  .contact__form-content .form__item-btn {
    margin-top: 0.68994375rem;
    height: 2.625rem;
    font-size: 1rem;
  }
}
.footer {
  background: var(--main);
  padding: 6.25rem 0 1.6875rem;
}
.footer__wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.footer__logo {
  flex: 0 0 12.1875rem;
  height: auto;
}
.footer__content {
  display: flex;
  align-items: start;
}
.footer__contact {
  margin-left: 3.6875rem;
  margin-right: 1.9375rem;
}
.footer__contact-item {
  display: flex;
  align-items: center;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--white);
  gap: 0.625rem;
}
.footer__contact-item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.footer__contact-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.footer__developer {
  display: block;
  margin-top: 0.6875rem;
  text-align: right;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--white);
  margin-right: 1rem;
}
.footer__mobile {
  display: none;
}

.footer .header__nav {
  flex: 0 0 55.3125rem;
  border-radius: 0px;
  background: none;
  padding: 0;
  margin-bottom: 2.5rem;
}
.footer .header__nav-list {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
}
.footer .nav__link {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--white);
}
.footer .nav__link:hover {
  -webkit-text-stroke: 1px white;
}

#footer__mobile {
  display: none;
}

@media (max-width: 800px) {
  .footer {
    display: none;
  }
  .footer__mobile {
    display: block;
    padding: 3.725rem 0 2.86875rem;
  }
  .footer__mobile .footer__wrapper {
    display: flex;
    justify-content: space-between;
  }
  .footer__mobile .header__nav-list {
    flex-direction: column;
  }
  .footer__mobile .footer__logo {
    width: 14.375rem;
    height: auto;
    margin-bottom: 1.1rem;
  }
  .footer__mobile .footer__contact {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .footer__mobile .footer__contact {
    margin-top: 1.25rem;
  }
  .footer__mobile .footer__contact-item {
    font-size: 0.875rem;
  }
  .footer__mobile .nav__link {
    font-size: 1rem;
  }
  .footer__mobile .footer__btn {
    width: 12.5rem;
    margin-bottom: 2.625rem;
  }
  .footer__mobile .footer__wrapper-item:first-child {
    max-width: 14.375rem;
  }
  .footer__mobile .header__nav {
    flex: 0 0 auto;
  }
  .footer__mobile .header__nav-list {
    gap: 0.825rem;
  }
  .footer__mobile .social {
    justify-content: end;
  }
  .footer__mobile .footer__developer {
    margin-top: 0;
    margin-right: 0;
    font-size: 0.75rem;
  }
}
@media (max-width: 550px) {
  #footer__middle {
    display: none;
  }
  #footer__mobile {
    display: block;
  }
  #footer__mobile {
    padding: 2.4725rem 0 2.86875rem;
  }
  #footer__mobile .footer__logo {
    width: 14.875rem;
    margin-bottom: 0.51rem;
  }
  #footer__mobile .nav__link {
    font-size: 0.875rem;
  }
  #footer__mobile .header__nav-item {
    line-height: normal;
  }
  #footer__mobile .header__nav-list {
    gap: 0.233825rem;
  }
  #footer__mobile .footer__contact-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  #footer__mobile .footer__phone {
    margin-top: 0.625rem;
  }
  #footer__mobile .social {
    margin-top: 2rem;
  }
  #footer__mobile .header__nav {
    margin-bottom: 0;
  }
  #footer__mobile .header__contact-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  #footer__mobile .footer__contact-item {
    gap: 0.2625rem;
  }
  #footer__mobile .footer__contact-item:not(:last-child) {
    margin-bottom: 0.25rem;
  }
  #footer__mobile .footer__btn {
    width: 100%;
    height: 2.625rem;
    font-weight: 500;
    font-size: 1rem;
    margin: 1.875rem 0;
  }
  #footer__mobile .footer__developer {
    text-align: center;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.modal-call {
  display: none;
}
.modal__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.625rem;
  width: 28.25rem;
  background: var(--light);
  padding: 2.5rem;
}
.modal__wrap {
  position: relative;
}
.modal__close {
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  top: -1.25rem;
  right: -1.25rem;
  cursor: pointer;
}
.modal__container {
  position: relative;
}
.modal__title {
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  text-align: center;
  color: var(--main);
  margin-bottom: 2rem;
  text-align: center;
}
.modal__text {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3.5rem;
}
.modal__item-label {
  display: block;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;
  color: var(--dark);
  margin-bottom: 1.25rem;
}
.modal__item-input {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  padding: 0 1.25rem;
  height: 3.25rem;
  outline: none;
  border: 1px solid rgba(42, 64, 123, 0.5);
  border-radius: 10px;
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--dark);
  opacity: 0.5;
}
.modal__item-btn {
  width: 100%;
  margin: 2.25rem 0 2rem;
}

.modal-call.active {
  display: block;
}

.modal__label p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 130%;
  color: var(--dark);
}
.modal__label-checkbox {
  display: flex;
  align-items: center;
  font-weight: 300;
}
.modal__checkbox {
  appearance: none;
}
.modal__checkbox-link {
  text-decoration: underline;
}
.modal__label-checkbox span {
  flex-shrink: 0;
  margin-right: 0.625rem;
  display: inline-block;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--main);
  border-radius: 0.3125rem;
}
.modal__label-checkbox input:checked + span {
  background: url("../img/form-union.svg") center no-repeat;
  background-size: contain;
  background-size: auto;
}

.modal__thanks {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.625rem;
  width: 28.25rem;
  height: 13.75rem;
  background: var(--light);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: var(--main);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
.modal__thanks span {
  display: block;
  margin-bottom: 1.375rem;
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  text-align: center;
  color: var(--main);
}
.modal__thanks-close {
  top: 1rem;
  right: 1.25rem;
}

.modal__thanks.active {
  display: flex;
}

@media (max-width: 550px) {
  .modal__form {
    width: 320px;
    padding: 40px 20px;
    border-radius: 10px;
  }
  .modal__title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .modal__item-label {
    font-size: 14px;
  }
  .modal__item-label:not(:last-child) {
    margin-bottom: 14px;
  }
  .modal__item-input {
    height: 42px;
    padding: 0 10px;
    font-weight: 300;
    font-size: 12px;
    margin-top: 0.15rem;
  }
  .modal__label p {
    font-size: 12px;
  }
  .modal__label-checkbox span {
    border-radius: 5px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .modal__item-btn {
    height: 42px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .modal__close {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -36px;
    right: 5px;
    cursor: pointer;
  }
  .modal__thanks {
    width: 320px;
    height: 184px;
  }
  .modal__thanks-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
  }
  .modal__thanks-text span {
    font-weight: 700;
    font-size: 20px;
  }
  .modal__thanks-close {
    width: 18px;
    height: 18px;
    top: 20px;
    right: 20px;
  }
}