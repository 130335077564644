.header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-top: 0.875rem;
  z-index: 100;

  &__wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 1.3125rem;
  }

  &__contact-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    color: var(--white);
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 130%;
    color: var(--white);
    margin-bottom: 0.75rem;
  }

  &__logo {
    width: 12.1875rem;
    height: auto;
    padding-top: 0.625rem;
    // height: vw(40);
  }

  &__contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.9375rem;
    margin-bottom: 1.25rem;
  }

  &__contact-item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }

  &__contact-time {
    p {
      font-family: var(--second-family);
      font-size: 0.875rem;
    }
  }

  &__contact-icon {
    width: 1.25rem;
    height: 1.25rem;

  }

  &__contact-language {
    position: relative;
    font-family: var(--second-family);
    font-size: 1rem;
    padding-right: 1.375rem;
    line-height: normal;
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: inline-block;
      content: ''; /* Выводим плюс */
      background: url("../img/language-arrow.svg") no-repeat center;
      background-size:  contain;
      width: 0.875rem;
      height: 0.5rem;
    }
  }

  &__contact-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 130%;
    color: var(--white);
  }
  &__contact-wrapper {
    flex: 0 0 55.4rem;
    // flex: 0 0 55.04rem;
  }
  &__bottom {
    display: flex;
    align-items: start;
    gap: 2.75rem;
  }

  &__nav {
    flex: 0 0 55.03125rem;
    border-radius: 0.625rem;
    background: rgba(255, 255, 255, 0.8);
    padding: 0.8125rem 4.0625rem 1.1125rem;
  }

  &__nav-list {
    display: flex;
    align-self: center;
    justify-content: space-between;
  }

  &__nav-item {
  }

  &__social {
    display: flex;
    gap: 0.5rem;
  }

  &__mobile {
    display: none;
  }
  &__contact-phone--img {
    display: none;
  }
}
.nav {

  &__link {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    color: var(--dark);
    &:hover {
      // text-shadow: 0px 4px 4px rgba(42, 64, 123, 0.1);
      // color: var(--main);
      -webkit-text-stroke: 1px var(--main);
  }
}
}
.social-link {
  width: 1.5rem;
  height: 1.5rem;
}



.header__fixed {
  display: none;
  background: var(--white);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  &.active {
    display: block;
  }
  .header__contact {
    display: block;
    margin-bottom: 0;
  }
  .header__contact-content {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 130%;
    color: var(--main);
  }
  .header__contact-email {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 130%;
    color: var(--main);
  }
  .header__nav {
    flex: 0 0 100%;
    border-radius: 0;
    background: transparent;
    padding: 0;
  }
  .header__contact-language {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 1rem;
    line-height: inherit;
    color: var(--main);
    cursor: pointer;
  }
  .header__contact-language::after {
    background: url("../img/language-arrow-color.svg") no-repeat center;
    background-size: contain;
  }
  .header__btn {
    width: 12.5rem;
    margin-top: 0.4rem;
  }
  .header__nav-list {
    align-items: baseline;
  }
}

.header__contact-language {
  &:nth-child(2):after{
    display: none;
  }
  &--wrap {
    position: relative;
    font-size: 1rem;
  }
  &--zh {
    display: none;
    position: absolute;
    top: 1rem;
    cursor: pointer;
    &.active {
      display: block;
    }
  }
  &--ru {
    cursor: pointer;
  }
  &.active::after {
    transform: rotate(180deg);
    top: 35%;
  }
}

@media (max-width: 800px) {
  .header__fixed.active {
    display: none;
  }
  .header {
    display: none;
  }

  .header__mobile {
    display: block;
    padding: 0.375rem 0;
    .header__wrapper {
      align-items: center;
    }
    .header__contact-wrapper {
      flex: 0 0 auto;
      position: relative;
    }
    .header__contact-content {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-bottom: 0;
      gap: 0px;
    }
    .header__contact-language {
      color: var(--white);
    }
    .header__contact-phone {
      font-size: 0.875rem;
    }
    .header__burger {
      width: 1.625rem;
      height: auto;
      cursor: pointer;
    }
    .header__logo {
      // width: 165px;
      width: 10.3125rem;
      height: auto;
      padding-top: 0rem;
    }
    .header__contact-icon {
      width: 1rem;
      height: 1rem;
    }
  }
}

// mobile burger

.header__nav-mobile {
  // display: none;
  position: absolute;
  right: -31.25rem;
  top: 0;
  z-index: 200;
  transition: all 0.5s ease;
}

.header__nav-mobile.active {
  right: 0;
  // display: block;
  transition: all 0.5s ease;
}
.header__nav-mobile--wrapper.active {
  width: 100%;
  height: 100vh;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  transition: all 0.5s ease;
}

.header__contact-language--wrap {
  width: 2.8rem;
}
.header__nav-mobile--content {
  border-radius: 0.625rem;
  width: 320px;
  background: var(--light);
  padding: 40px 50px;
  .header__nav-list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    margin: 0;
  }
  .btn-light {
    height: 52px;
    font-size: 16px;
  }
  .modal__close {
    width: 18px;
    height: 18px;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .header__contact-wrapper {
    margin-bottom: 2.5rem;
  }
  .header__nav {
    margin-bottom: 40px;
    padding: 0;
    background: transparent;
    border-radius: 0;
  }
  .header__contact-item {
    gap: 0.4625rem;
  }
  .header__contact-item:first-child {
    margin-bottom: 0.875rem;
  }
  .header__btn {
    width: 100%;
  }
  .header__nav-item:nth-child(3)::after {
    background-position: right 0.242rem;
  }
  .nav__link {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: var(--dark);
    &:hover {
      // color: var(--main);
      // text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      -webkit-text-stroke: 1px var(--main);
    }
  }
  .header__contact-location {
    flex-direction: column;
    align-items: start;
    gap: 14px;
    margin-bottom: 40px;
    font-family: var(--second-family);
font-weight: 400;
font-size: 14px;
line-height: 130%;
color: var(--dark);
  }
  .header__contact-item:first-child {
    margin-bottom: 0rem;
}
.btn-light {
  width: 100%;
}
.header__contact-time p{
  font-size: 14px;
}
}

.just-validate-error-label {
  display: none;
}

@media (max-width: 800px) {
  .header__contact-language--wrap {
    width: 2.7rem;
  }
  .header__nav-mobile--content {
    .header__social {
      display: none;
    }
    .header__contact-wrapper {
      display: none;
    }
  }
}

@media (max-width: 550px) {
  .header__contact-language--wrap {
    width: 2.7rem;
  }
  .header__mobile {
    display: block;
      padding: 0.684375rem 0;
    .header__social {
      display: none;
    }
    .header__contact-phone--img {
      display: inline-block;
      padding-right: 1.2rem;
      position: relative;
      cursor: pointer;
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: inline-block;
        content: ''; /* Выводим плюс */
        background: url("../img/language-arrow.svg") no-repeat center;
        background-size:  contain;
        width: 0.875rem;
        height: 0.5rem;
      }
      // &.active::after {
      //   transform: rotate(180deg);
      //   top: 35%;
      //   transition: transform 0.5s;
      // }
    }
    .header__contact-content {
      display: none;
      position: absolute;
      width: max-content;
      left: -6rem;
        top: 2.2rem;
    }
    .header__contact-phone {
      font-size: 0.875rem;
      line-height: 148%;
    }
    .header__logo {
      width: 156px;
      width: 9.75rem;
      height: 2rem;
    }
    .header__contact-language {
      font-size: 0.875rem;
    }

    .header__wrapper {
      gap: 0;
    }
    .header__burger{
      width: 24px;
      height: auto;
    }

  }
  #contact-content {
  &.active {
    display: block;
    }
  }

  #contact-phone.active::after {
    transform: rotate(180deg);
    top: 35%;
  }
}




@media (max-width: 550px) {
  .header__nav-mobile--content{
    .header__nav-item {
      line-height: 40%;
    }
    .header__nav-list {
      gap: 16px;
    }
    .header__social {
      display: flex;
    }
    .header__contact-wrapper {
      display: flex;
      margin-bottom: 0;
    }
    .header__social {
      gap: 8px;
      align-items: start;
      justify-content: start;
      margin-bottom: 30px;
    }
    .social-link {
      display: flex;
      width: 24px;
      height: 24px;
    }
    .header__contact-content {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
    .header__contact-phone {
      font-family: var(--second-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: var(--dark);
      gap: 2px;
    }
    .header__contact-icon {
      width: 20px;
      height: 20px;
  }
  .header__nav {
    margin-bottom: 30px;
  }
  .modal__close {
    display: flex;
  }
  .header__contact-content {
    margin-bottom: 20px;
  }
.header__contact-item  {
  gap: 6px;
}
.header__contact-address {
  font-size: 14px;
}
.header__contact-location {
  gap: 8px;
  margin-bottom: 30px;
}
.header__contact-email {
  font-size: 14px;
}
.btn-light {
  border-radius: 10px;
}
  }
}


