.main {

  &__section {
    position: relative;
    background: url("../img/bg-main.jpg") no-repeat center;
    background-size: cover;
    padding: 13.125rem 0 6.3125rem;
    border-radius: 0 0 0.625rem 0.625rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &__wrapper {
    max-width: 46.75rem
  }

  &__title {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 4.125rem;
    line-height: 100%;
    color: var(--main);
    margin-bottom: 4.625rem;
    span{
      font-weight: 800;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 3.75rem;
  }

  &__item {
    display: flex;
    align-items: center;
    // gap: 0.6875rem 1.596875rem;
    gap: 0.75rem;
    flex: 0 0 22.75rem;
    padding: 0.5rem 0.625rem;
    border-radius: 0.625rem;
    background: rgba(255, 255, 255, 0.8);
  }

  &__item-icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    img {
      opacity: 0.8;
    }
  }

  &__item-text {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 1rem;
    line-height: 130%;
    color: var(--dark);
    span {
      font-weight: 700;
    }
  }

  &__btn {
    width: 22.75rem;
    background: var(--accent);
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--white);
    &:hover {
      box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
      background: #ff7919;
      transition: background 0.2s ease;
    }
  }
  &__circle {
    position: absolute;
    bottom: -3.75rem;
    left: 50%;
    transform: translateX(-50%);
    width: 7.5rem;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    img {
      width: 6rem;
      height: 6rem;
    }
  }
}

@media (max-width: 800px) {
  .main__title {
    font-size: 3rem;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 2.4625rem;
  }
  .main__section {
    padding: 8.8125rem 0 3.93125rem;
    margin-bottom: 0;
  }
  .main__item {
    gap: 0.7099875rem;
    flex: 0 0 19.8375rem;
    padding: 0.5rem 0.625rem;

  }
  .main__item-text {
    font-size: 0.875rem;
  }
  .main__btn {
    width: 19.2875rem;
  }
  .main__content {
    margin-bottom: 2.275rem;
}
.main__circle img {
  width: 4.375rem;
  height: 4.375rem;
}
.main__circle {
  width: 5.375rem;
  height: 5.375rem;
  bottom: -2.75rem;
}
}

@media (max-width: 550px) {
  .main__title {
    font-size: 1.845rem;
    line-height: 110%;
    letter-spacing: -0.02em;
    margin-bottom: 1.25rem;
    font-weight: 500;
    text-transform: none;
  }
  .main__section {
    padding: 4.48125rem 0 3.4593125rem;
    margin-bottom: 0;
    &.active {
      padding-top: 7.8948125rem
    }
}
.main__item {
  gap: 0.7099875rem;
  flex: 0 0 100%;
  padding: 0.35rem 0.625rem;
}
.main__item-text {
  font-size: 0.75rem;
}
.main__content {
  gap: 0.625rem;
  margin-bottom: 1.8275rem;
}
.main__btn {
  width: 100%;
}
    .main__circle img {
      width: 3rem;
      height: 3rem;
}
.main__circle {
  width: 3.75rem;
  height: 3.75rem;
  bottom: -1.75rem;
}
}
