.team-slider {

		&__item {
      overflow: hidden;
      border-radius: 0.625rem;
      height: 30.375rem;
      box-shadow: 0 0 25px 0 rgba(40, 65, 123, 0.1);
      margin-bottom: 0.8125rem;
		}

    &__item-content {
      padding: 1.25rem 1.503125rem;
    }

		&__item-img {
      width: 100%;
      height: 20.5rem;
		}

		&__item-title {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 130%;
      text-align: center;
      color: var(--main);
      margin-bottom: 0.875rem;
		}

		&__item-email {
      display: flex;
      align-items: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--dark);
      margin-bottom: 0.625rem;
      gap: 0.625rem;
      span {
        font-weight: 700;
      }
		}

		&__item-position {
      display: flex;
      align-items: start;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--dark);
      gap: 0.625rem;
      span:first-child {
        font-weight: 700;
      }
		}
}

.team-slider {
  &__section {
    padding-bottom: 11.875rem;
  }
  &__container {
    padding: 0 7rem;
  }
  &__wrap {
    position: relative;
    width: 100%;
  }
  &__btns {
    display: flex;
    gap: 1.3125rem;
  }
  &__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 3rem;
    height: 3rem;
    background: rgba(42, 64, 123, 0.05);
    &::after {
      content: "";
      display: inline-block;
      width: 1.3125rem;
      height: 1rem;
    }
    }
  &__btn-prev {
    &::after {
      background: url('../img/btn-arrow-prev.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-next {
    &::after {
      background: url('../img/btn-arrow-next.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-wrapper {
    position: absolute;
    bottom: calc(-5.5625rem + 0.8125rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__pagination-wrapper {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    p {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: var(--dark);
    }
  }
  &__pagination {
    width: auto !important;
  }
  &.active {
    display: block;
  }
  &.desactive {
    display: none;
  }
}


.team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}
.swiper-pagination-current {
  color: var(--accent);
}



@media (max-width: 800px) {
  .team-slider__item {
    // flex: 0 0 13.9990625rem;
    min-height: 20.75rem;
    height: auto;
  }
  .team-slider__item-img {
    width: 100%;
    height: 12.65rem;
}
.team-slider__item-title {
  font-size: 1.25rem;
line-height: 110%;
margin-bottom: 0.875rem;
font-weight: 500;
}
.team-slider__item-email {
  font-size: 0.875rem;
line-height: 140%;
margin-bottom: 0.5rem;
}
.team-slider__item-position {
  font-size: 0.75rem;
line-height: 130%;
gap: 0.375rem;
}
.team-slider__item-position span:first-child {
  font-size: 0.875rem;
}
  .team-slider__item-content {
      padding: 0.875rem 0.375rem 0 0.375rem;
  }

.team-slider__item-email {
  gap: 0.375rem;
}
.team-slider__container {
  padding: 0rem;
}

.team-slider__btn-wrapper {
  position: absolute;
  bottom: -4.35625rem;
}
.team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-size: 1.25rem;
}
.team-slider__pagination-wrapper p {
  font-size: 0.75rem;
}
.team-slider__pagination-wrapper {
  gap: 1rem;
}
.team-slider__section {
  padding-bottom: 8.52875rem;
}
}

@media (max-width: 550px) {
  .team-slider__item {
    min-height: 23.8125rem;
}
  .team-slider__item-img {
    width: 100%;
    height: 15.765rem;
  }
  .team-slider__item-content {
    padding: 0.5875rem 0.375rem 0 1.7375rem;
}
.team-slider__item-email {
  gap: 0.5375rem;
}
.team-slider__item-position {
  gap: 0.5375rem;
}
.team-slider__item-position {
  line-height: 140%;
  font-size: 0.875rem;
}

.team-slider__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-size: 1.25rem;
  flex: 0 0 3rem;
}
.team-slider__pagination-wrapper p {
  font-size: 0.625rem;
  padding-top: 0.1875rem;
}
.team-slider__pagination-wrapper {
gap: 0.2625rem;
}
.team-slider__btn {
position: relative;
border-radius: 0.625rem;
width: 2.5rem;
height: 2.5rem;
background: rgba(42, 64, 123, 0.05);
}
.team-slider__btn::after {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 1rem;
height: 0.75rem;
}
.team-slider__btn-wrapper {
position: absolute;
bottom: -2.3535625rem;
}
.team-slider__btns {
gap: 0.875rem;
}
.team-slider__section {
  padding-bottom: 5.352875rem;
}
}

