.consultation {

		&__section {
      background: var(--main);
		}

		&__title {
      color: var(--white);
      margin-bottom: 1.525rem;
		}

		&__subtitle {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 1.5rem;
      color: var(--white);
      margin-bottom: 3.75rem;
      text-align: center;
		}

		&__wrapper {
      padding: 0 1.875rem;
		}

}

.form {

		&__content {
      display: flex;
      align-items: end;
      gap: 1.25rem;
      justify-content: space-between;
		}

		&__item-label {
      position: relative;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      color: var(--white);
		}

		&__item-input {
      display: block;
      width: 100%;
      padding: 0 1.1875rem;
      width: 22.625rem;
      height: 3.25rem;
      outline:none;
      border: 1px solid var(--main);
      border-radius: 0.625rem;

      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: rgba(7, 17, 38, 0.5);
		}

		&__item-btn {
      border-radius: 0.625rem;
      width: 16.9375rem;
      height: 3.25rem;
      background: var(--accent);
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--white);
      &:hover {
        box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
        background: #ff7919;
        transition: background 0.2s ease;
      }
		}
}

input.just-validate-error-field {
  border: 1px solid #f00;
}
.just-validate-error-label {
  position: absolute;
  color: rgb(212 26 26);
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.consultation {

  &__label-checkbox {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;
    font-weight: 300;
    p {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: var(--white);
    }
  }

  &__checkbox {
      appearance: none;
  }
  &__checkbox-link {
      text-decoration: underline;
  }
  &__label-checkbox--mobile {
    display: none;
  }
}
.consultation__label-checkbox span{
  flex-shrink: 0;
  margin-right: 0.625rem;
  display: inline-block;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--white);
  border-radius: 0.3125rem;
}
.consultation__label-checkbox input:checked + span{
  background: url('../img/form-union.svg') center no-repeat;
  background-size: contain;
  background-size: auto;
}

@media (max-width: 800px) {
  .consultation__subtitle {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 2.475rem;
  }
  .consultation__wrapper {
    padding: 0;
  }
  .consultation__form {
    .form__content {
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 0.825rem;
    }
    .form__wrap {
      max-width: 22.625rem;
      width: 100%;
      margin: 0 auto;
    }
    .form__item-input {
      width: 100%;
      margin-top: 0.2rem;
    }
    .form__item-label {
      width: 100%;
      font-size: 0.875rem;
      line-height: 140%;
    }
    .form__item-btn {
      width: 100%;
      margin-top: 1.5rem;
    }
    .consultation__label-checkbox {
      display: none;
    }
    .consultation__label-checkbox--mobile {
      display: flex;
      margin-top: 0;
    }
  }
}


@media (max-width: 550px) {
  .consultation__subtitle {
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 1.2475rem;
  }
  .consultation__title {
    margin-bottom: 0.3525rem;
  }
  .consultation__form {
    .form__item-label {
      font-size: 0.75rem;
      line-height: 130%;
    }
    .form__item-input {
      height: 2.625rem;
      font-weight: 300;
      font-size: 0.75rem;
      padding: 0 0.625rem;
      margin-top: 0.32rem;
    }
    .consultation__label-checkbox p {
      font-weight: 300;
      font-size: 0.75rem;
    }
    .form__item-btn {
      height: 2.625rem;
      font-weight: 700;
      font-size: 1rem;
      line-height: 110%;
      margin-top: 0.585rem;
    }

  }

}


