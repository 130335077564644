.values {

		&__section {
      background: var(--light);
      padding-bottom: 0;
		}

		&__wrapper {
      display: flex;
      justify-content: center;
      gap: 1.875rem;
      margin-left: 6.25rem;
		}

		&__item {
      &:first-child {
        flex: 0 0 33.25rem;
      }
      &:last-child {
        flex: 0 0 40.3125rem;
        .values__item-text {
          width: 80%;
        }
      }
		}

		&__item-text {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--dark);
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
		}

		&__item-img {
      border-radius: 0.625rem;
      width: 40.03125rem;
      height: auto;
		}
}

@media (max-width: 800px) {
  .values__item-text {
    font-size: 0.875rem;
    line-height: 140%;
    margin-bottom: 1.25rem;
  }
  .values__item-img {
    width: 22.125rem;
  height: 21.0625rem;
  border-radius: 0.625rem;
  overflow: hidden;
  }
  .values__wrapper {
    margin-left: 0;
  }
  .values__item:first-child {
    flex: 0 0 48%;
  }
  .values__item:last-child {
    flex: 0 0 50%;
  }
  .values__item:last-child .values__item-text {
    width: 100%;
  }

}

@media (max-width: 550px) {
  .values__wrapper {
    flex-direction: column;
    gap: 0px;
  }
  .values__item:first-child {
    flex: 0 0 100%;

}
.values__item:last-child {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: -0.7875rem;
  .values__item-img {
    order: 1;
  }
  .values__item-text {
    order: 2;
  }
}
.values__item-img {
  width: 100%;
  height: 12.5rem;
}
.values__item-text {
  font-size: 0.75rem;
line-height: 130%;
}
.values__item-text:not(:last-child) {
  margin-bottom: 0.75rem;
}
.values__section {
  padding-bottom: 1.7rem;
}
}
