.team {

		&__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.875rem;
		}

		&__item {
      overflow: hidden;
      border-radius: 0.625rem;
      flex: 0 0 21.5625rem;
      height: 30.375rem;
      box-shadow: 0 0 25px 0 rgba(40, 65, 123, 0.1);
		}

    &__item-content {
      padding: 1.25rem 2.3125rem;
    }

		&__item-img {
      width: 100%;
      height: 20.5rem;
		}

		&__item-title {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 130%;
      text-align: center;
      color: var(--main);
      margin-bottom: 0.875rem;
		}

		&__item-email {
      display: flex;
      align-items: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--dark);
      margin-bottom: 0.625rem;
      gap: 0.625rem;
      span {
        font-weight: 700;
      }
		}

		&__item-position {
      display: flex;
      align-items: start;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--dark);
      gap: 0.625rem;
      span:first-child {
        font-weight: 700;
      }
		}
    &.active {
      display: block;
    }
    &.desactive {
      display: none;
    }
}

@media (max-width: 800px) {
  .team__item {
    flex: 0 0 13.9990625rem;
    min-height: 20.75rem;
    height: auto;
  }
  .team__item-img {
    width: 100%;
    height: 12.65rem;
}
.team__item-title {
  font-size: 1.25rem;
line-height: 110%;
margin-bottom: 0.875rem;
font-weight: 500;
}
.team__item-email {
  font-size: 0.875rem;
line-height: 140%;
margin-bottom: 0.5rem;
}
.team__item-position {
  font-size: 0.75rem;
line-height: 130%;
gap: 0.375rem;
}
.team__item-position span:first-child {
  font-size: 0.875rem;
}
  .team__item-content {
      padding: 0.875rem 0.375rem 0 0.375rem;
  }
  .team__wrapper {
    gap: 1.25rem;
}
.team__item-email {
  gap: 0.375rem;
}
}

@media (max-width: 550px) {
  .team__section {
    display: none;
  }
}





