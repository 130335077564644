.portfolio {
  &__section {
    padding-bottom: 11.875rem;
    background: var(--light);
  }
  &__wrap {
    position: relative;
  }
  &__btns {
    display: flex;
    gap: 1.3125rem;
  }
  &__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 3rem;
    height: 3rem;
    background: rgba(42, 64, 123, 0.05);
    &::after {
      content: "";
      display: inline-block;
      width: 1.3125rem;
      height: 1rem;
    }
    }
  &__btn-prev {
    &::after {
      background: url('../img/btn-arrow-prev.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-next {
    &::after {
      background: url('../img/btn-arrow-next.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-wrapper {
    position: absolute;
    bottom: -5.5625rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__pagination-wrapper {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    p {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: var(--dark);
    }
  }
  &__pagination {
    width: auto !important;
  }
}


.portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}
.swiper-pagination-current {
  color: var(--accent);
}


.portfolio {

		&__slide {
		}

		&__item {
		}

		&__slide-wrap {
      display: flex;
      justify-content: space-between;
      gap: 1.875rem;
      // min-height: 34.875rem;
      // height: 100%;
		}

		// &__slide-item {
    //   flex: 0 0 50%;
    //   position: relative;
		// }

    &__slide-item--content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.25rem;
    }

		&__slide-content {
		}

		&__slide-title {
      margin-bottom: 1.25rem;
      font-family: var(--second-family);
      font-weight: 800;
      font-size: 2rem;
      line-height: 100%;
      color: var(--dark);
		}

		&__slide-text {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--dark);
		}

		&__slide-subtitle {
      margin-bottom: 1.25rem;
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 130%;
      color: var(--dark);
		}
    &__slide-btn {
      width: 21.25rem;
      font-size: 1.25rem;
    }
    &__slide-btn--mobile {
      display: none;
    }
}

// .portfolio__slide-wrap {
//   min-height: 34.875rem;
// }

.portfolio__slider-main {
  min-height: 26.25rem;
  // height: 71.3%;
}

.portfolio__slide-item {
  // min-height: 34.875rem;
  width: 49%;
}
.image-mini-slider {
  // margin-top: 0.2425rem;
  margin-top: 0.625rem;
  .swiper-slide {
    overflow: hidden;
    border-radius: 0.625rem;
  }
}
.swiper-slide.portfolio__slider-main--slide {
  overflow: hidden;
  border-radius: 0.625rem;
  height: 26.25rem;
}

.image-mini-slider {
  position: relative;
  // height: 18%;
  .swiper-button-prev:after {
    position: absolute;
    display: inline-block;
    content: "";
    width: 1.25rem;
    height: 1.25rem;
    background: url("../img/portfolio-left.svg") no-repeat center;
    background-size: contain;
  }
  .swiper-button-next:after {
    position: absolute;
    display: inline-block;
    content: "";
    width: 1.25rem;
    height: 1.25rem;
    background: url("../img/portfolio-right.svg") no-repeat center;
    background-size: contain;
  }
  .swiper-button-next, .swiper-button-prev {
    top: auto;
    bottom: 3.5rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  .swiper-button-prev--second{
    left: 10px;
    &:after {
      position: absolute;
      display: inline-block;
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      background: url("../img/portfolio-left.svg") no-repeat center;
      background-size: contain;
    }
  }
  .swiper-button-next--second {
    right: 10px;
    &:after {
      position: absolute;
      display: inline-block;
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      background: url("../img/portfolio-right.svg") no-repeat center;
      background-size: contain;
    }
  }
  .swiper-button-next--second, .swiper-button-prev--second {
    position: absolute;
    top: auto;
    bottom: 3.5rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    z-index: 100;
  }

  .swiper-slide {
    height: 8rem;
  }
}

.image-mini-slider .swiper-slide {
  opacity: 0.4;
}

.image-mini-slider .swiper-slide-active {
  opacity: 1;
}


@media (max-width: 800px) {
  .portfolio__slide-title {
    font-size: 1.25rem;
    margin-bottom: 0.875rem;
  }
  .portfolio__slide-text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .portfolio__slide-subtitle {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.625rem;
  }
  .portfolio__slide-btn {
    width: 21.25rem;
    font-size: 1.25rem;
  }
  .portfolio__slider-main {
    min-height: 16.8rem;
}

.image-mini-slider {
    margin-top: 0.52425rem;
    height: 5.2rem;
    .swiper-slide {
      height: 5.2rem;
    }
}
.portfolio__slide-item {
 min-height: auto;
  width: 49%;
}
.image-mini-slider .swiper-button-next, .image-mini-slider .swiper-button-prev,
.image-mini-slider .swiper-button-next--second, .image-mini-slider  .swiper-button-prev--second {
  bottom: 1.995rem;
}
.portfolio__slide-wrap {
  gap: 1rem;
}
.portfolio__btn-wrapper {
  bottom: -4.25625rem;
}
.portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-size: 1.25rem;
}
.portfolio__pagination-wrapper p {
  font-size: 0.75rem;
}
.portfolio__pagination-wrapper {
  gap: 1rem;
}
.portfolio__section {
  padding-bottom: 7.99875rem;
}
.swiper-slide.portfolio__slider-main--slide {
  height: 16.875rem;
}
}

@media (max-width: 550px) {
  .portfolio__slide-wrap {
    flex-direction: column;
    align-items: start;
    gap: 1.556875rem;
  }
  .portfolio__slide-item {
    min-height: auto;
    width: 100%;
}
.portfolio__title {
  margin-bottom: 2.375rem;
}
.portfolio__slider-main {
  min-height: auto;
}
.swiper-slide.portfolio__slider-main--slide {
  height: 13.8125rem;
}
.image-mini-slider {
  height: auto;
}
.image-mini-slider .swiper-slide {
  height: 4.52rem;
}
.portfolio__slide-title {
  font-size: 1.25rem;
  margin-bottom: 0.5875rem;
}
.portfolio__slide-text {
  font-size: 0.75rem;
}
.portfolio__slide-btn {
  display: none;
}
.portfolio__slide-btn--mobile {
  display: block;
  width: 100%;
  font-size: 1.25rem;
  font-size: 1rem;
  height: 2.625rem;
}
.portfolio__slider {
  margin-bottom: 4.6rem;

}
.portfolio__btn-wrapper {
  bottom: 3.8rem;
}
.portfolio__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-size: 1.25rem;
    flex: 0 0 3rem;
}
.portfolio__pagination-wrapper p {
  font-size: 0.625rem;
    padding-top: 0.1875rem;
}
.portfolio__btn {
  position: relative;
  border-radius: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(42, 64, 123, 0.05);
}
.portfolio__btn::after  {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 0.75rem;
}
.portfolio__btns {
  gap: 0.875rem;
}
.portfolio__pagination-wrapper {
  gap: 0.2625rem;
}
.portfolio__slide-subtitle {
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
}
.portfolio__slide-item--content{
  gap: 1rem;
}
.portfolio__section {
  padding-bottom: 2.399875rem;
}
.image-mini-slider .swiper-button-next, .image-mini-slider .swiper-button-prev,
.image-mini-slider .swiper-button-next--second, .image-mini-slider  .swiper-button-prev--second {
  bottom: 1.52995rem;
}
}
