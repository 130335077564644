.certificates {
  &__section {
    padding-bottom: 11.5875rem;
  }
  &__item {
    height: 26.625rem;
    width: auto;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  &__wrap {
    position: relative;
  }
  &__btns {
    display: flex;
    gap: 1.3125rem;
  }
  &__btn {
    position: relative;
    border-radius: 0.625rem;
    width: 3rem;
    height: 3rem;
    background: rgba(42, 64, 123, 0.05);
    &::after {
      content: "";
      display: inline-block;
      width: 1.3125rem;
      height: 1rem;
    }
    }
  &__btn-prev {
    &::after {
      background: url('../img/btn-arrow-prev.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-next {
    &::after {
      background: url('../img/btn-arrow-next.svg') no-repeat center;
      background-size: contain;
    }
  }
  &__btn-wrapper {
    position: absolute;
    bottom: -5.5625rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.desactive {
      display: none;
    }
  }
  &__pagination-wrapper {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    p {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: var(--dark);
    }
  }
  &__pagination {
    width: auto !important;
  }
}


.certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  color: rgba(7, 17, 38, 0.5);
}
.swiper-pagination-current {
  color: var(--accent);
}

.fancybox-image, .fancybox-spaceball {
  border-radius: 1rem;
}



@media (max-width: 800px) {
  .certificates__section {
    padding-bottom: 8.0875rem;
    padding-top: 2.2rem;
  }
  .certificates__item {
    height: 19.8625rem;
  }
  .certificates__btn-wrapper {
    position: absolute;
    bottom: -4.35625rem;
  }
  .certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
  }
  .certificates__pagination-wrapper p {
    font-size: 0.75rem;
  }
  .certificates__pagination-wrapper {
    gap: 1rem;
  }
}

@media (max-width: 550px) {
  .certificates__section {
    padding-bottom: 5.470875rem;
    padding-top: 0.42rem;
  }
  .certificates__item {
    height: 23.8375rem;
}
  .certificates__slider {
    width: 80%;
  }
  .certificates__pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-size: 1.25rem;
    flex: 0 0 3rem;
  }
  .certificates__pagination-wrapper p {
    font-size: 0.625rem;
    padding-top: 0.1875rem;
}
.certificates__pagination-wrapper {
  gap: 0.2625rem;
}
.certificates__btn {
  position: relative;
  border-radius: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(42, 64, 123, 0.05);
}
.certificates__btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 0.75rem;
}
.certificates__btn-wrapper {
  position: absolute;
  bottom: -3.0535625rem;
}
.certificates__btns {
  gap: 0.875rem;
}
}

@media (max-width: 400px) {
  .certificates__slider {
    width: 93%;
  }
}
