// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Lato", sans-serif;
  --content-width: 82.5rem;
  --container-offset: 3.75rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family: "Lato", sans-serif;
  --second-family: "Montserrat", sans-serif;
  --third-family: "Inter", sans-serif;
  --font3: "Open Sans", sans-serif;

  // colors
  --main: rgb(42, 64, 123);
	--accent: #ea6709;
	--dark: #071126;
	--white: #fff;
	--light: #f2f4f7;
	--dark: #071126;

}
