.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;

  &-call {
    display: none;
  }

  &__form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.625rem;
    width: 28.25rem;
    background: var(--light);
    padding: 2.5rem;
  }

  &__wrap {
    position: relative;
  }
  &__close {
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    top: -1.25rem;
    right: -1.25rem;
    cursor: pointer;
  }

  &__container {
    position: relative;
  }
  &__title {
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 2rem;
    line-height: 100%;
    text-align: center;
    color: var(--main);
    margin-bottom: 2rem;
    text-align: center;
  }
  &__text {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3.5rem;
  }

  &__item-label {
    display: block;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1rem;
    line-height: 130%;
    color: var(--dark);
    margin-bottom: 1.25rem;
  }

  &__item-input {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    padding: 0 1.25rem;
    height: 3.25rem;
    outline: none;
    border: 1px solid rgba(42, 64, 123, 0.5);
    border-radius: 10px;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 130%;
    color: var(--dark);
    opacity: 0.5;
  }

  &__item-btn {
    width: 100%;
    margin: 2.25rem 0 2rem;
  }
}

.modal-call.active {
  display: block;
}
.modal {
  &__label p{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 130%;
    color: var(--dark);
  }

  &__label-checkbox {
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  &__checkbox {
      appearance: none;
  }
  &__checkbox-link {
      text-decoration: underline;
  }
  &__label-checkbox span{
    flex-shrink: 0;
    margin-right: 0.625rem;
    display: inline-block;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--main);
    border-radius: 0.3125rem;
  }
  &__label-checkbox input:checked + span{
    background: url('../img/form-union.svg') center no-repeat;
    background-size: contain;
    background-size: auto;
  }
}


.modal__thanks {
  // display: flex;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.625rem;
  width: 28.25rem;
  height: 13.75rem;
  background: var(--light);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: var(--main);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  z-index: 1000;

  span {
    display: block;
    margin-bottom: 1.375rem;
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 2rem;
    line-height: 100%;
    text-align: center;
    color: var(--main);
  }

  &-close{
    top: 1rem;
    right: 1.25rem;
  }
}
.modal__thanks.active{
  display: flex;
}


@media (max-width: 550px) {
  .modal__form {
    width: 320px;
    padding: 40px 20px;
    border-radius: 10px;
  }
  .modal__title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .modal__item-label {
    font-size: 14px;
  }
  .modal__item-label:not(:last-child) {
    margin-bottom: 14px;
  }
  .modal__item-input {
    height: 42px;
    padding: 0 10px;
    font-weight: 300;
    font-size: 12px;
    margin-top: 0.15rem;
  }
  .modal__label p {
    font-size: 12px;
  }
  .modal__label-checkbox span {
    border-radius: 5px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .modal__item-btn {
    height: 42px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .modal__close {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -36px;
    right: 5px;
    cursor: pointer;
}
  .modal__thanks {
    width: 320px;
    height: 184px;
}
.modal__thanks-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  span {
    font-weight: 700;
    font-size: 20px;
  }
  }
  .modal__thanks-close {
    width: 18px;
    height: 18px;
    top: 20px;
    right: 20px;
  }
}
