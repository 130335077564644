.about {

  &__section {
    position: relative;
    // background: url('../img/about-bg.png') no-repeat center;
    // background-size: cover;
    margin-top: 1.5rem;
    padding-top: 5rem;
    padding-bottom: 5.2rem;
    margin-bottom: 0.7rem;
  }
  &__section-bg {
    position: absolute;
    top: 0;
    left: 0;
    // bottom: 0;
    // right: 0;
    width: 100%;
    z-index: -1;
  }
  &__title {
    color: var(--white);
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.5625rem;
  }

  &__item {
    &:first-child {
      flex: 0 0 26.5625rem;
    }
    &:last-child {
      .about__item-text {
        padding-left: 1rem;
        width: 95%;
      }
    }
  }

  &__item-text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1.1875rem;
    line-height: 130%;
    color: var(--white);
  }
  &__item-text--bg {
    border-radius: 0.625rem;
    background: rgba(255, 255, 255, 0.2);
    padding: 1.25rem;
    margin-bottom: 2.0875rem;
  }

  &__item-img {
    border-radius: 0.625rem;
    width: 40.375rem;
    height: auto;
    margin-top: 2.053125rem;
  }
  &__item-wrap {
    .about__item-text:not(:last-child) {
      margin-bottom: 0.9625rem;
    }
  }
}

@media (max-width: 800px) {
  .about__section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .about__item-text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .about__item-wrap .about__item-text:not(:last-child) {
    margin-bottom: 0.625rem;
}
  .about__item-text--bg {
    padding: 1rem;
    margin-bottom: 1.25rem;
  }
  .about__item-img {
    width: 23.875rem;
    height: 13.6875rem;
    margin-top: 1.25rem;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  .about__item:first-child {
    flex: 0 0 20.3834rem;
}
.about__wrapper {
  gap: 1.0015625rem;
}
.about__item:last-child .about__item-text {
  padding-left: 0rem;
  width: 100%;
}
.about__section-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}
}

@media (max-width: 550px) {
  .about__section {
    padding-top: 3.7rem;
    padding-bottom: 2.162rem;
  }
  .about__wrapper {
    flex-direction: column;
  }
  .about__item:first-child {
    flex: 0 0 100%;
  }
  .about__item {
    flex: 0 0 100%;
  }
  .about__item-img {
    width: 100%;
    height: 12.5rem;
    margin-top: 0rem;
    margin-bottom: 0.625rem;
  }
  .about__item-text {
    font-size: 0.75rem;
  }
  .about__item-text--bg {
    padding: 0.6rem;
    margin-bottom: 0.625rem;
  }
  .about__item:last-child {
    display: flex;
    flex-direction: column;
    .about__item-img {
      order: 1;
    }
    .about__item-text {
      order: 2;
    }
  }
}
