.advantages {
    &__section {
      padding-bottom: 6.25rem;
      padding-top: 6.25rem;
    }

		&__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2.7875rem 1.5875rem;
		}

    &__main-title {
      margin-bottom: 4.4rem;
    }

		&__item {
      position: relative;
      flex: 0 0 49%;
		}

    &__img {
      position: absolute;
      right: 1.25rem;
      top: -1.49375rem;
      // width: auto;
      width: 10rem;
      height: 5.9375rem;
      img {
        object-fit: contain;
      }
    }

		&__title {
      font-family: var(--second-family);
      font-weight: 800;
      font-size: 2rem;
      line-height: 100%;
      color: var(--main);
      padding-bottom: 1.5625rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid var(--accent);
		}

		&__text {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--dark);
		}
}

@media (max-width: 800px) {
  .advantages {
    padding-top: 3.75rem;
  }
  .advantages__main-title {
    margin-bottom: 2.5rem;
  }
  .advantages__title {
    font-size: 1.25rem;
    margin-bottom: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .advantages__text {
    font-size: 0.875rem;
    line-height: 140%;
  }
  .advantages__img {
    height: 2.9375rem;
    width: 4.8rem;
    right: 0.525rem;
    top: 0.050625rem;
  }
  .advantages__wrapper {
    gap: 1.47875rem 0.5875rem;
}
.advantages__section {
  padding-bottom: 2.525rem;
  padding-top: 3.75rem;
}
}

@media (max-width: 550px) {
  .advantages__section {
    padding: 2.5rem 0;
    padding-bottom: 1rem;
  }
  .advantages__item {
    flex: 0 0 100%;
}
  .advantages__title {
    font-size: 1rem;
    line-height: 110%;
    margin-bottom: 0.375rem;
    padding-bottom: 0.5625rem;
  }
  .advantages__main-title {
    margin-bottom: 1.675rem;
}
.advantages__text {
  font-size: 0.75rem;
line-height: 130%;
}
.advantages__img {
  height: 2.249375rem;
  width: 4rem;
  right: 0.0525rem;
  top: -0.250625rem;
}
}
