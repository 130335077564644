.cargo {

		&__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.875rem;
		}

		&__item {
      position: relative;
      border-radius: 0.625rem;
      background: var(--accent);
      color: var(--white);
      padding: 2.4375rem 1.9375rem;
      height: 16.25rem;
      &:first-child {
        flex: 0 0 40.3125rem;
        .cargo__img {
          width: 18.9375rem;
          height: auto;
          right: 1.2rem;
          bottom: 0.5rem;
        }
      }
      &:nth-child(2) {
        flex: 0 0 26.25rem;
        .cargo__img {
          width: 19.4375rem;
          height: auto;
          bottom: -1.4rem;
        }
      }
      &:last-child{
        flex: 0 0 100%;
        background: var(--main);
        .cargo__img {
          width: 27.3125rem;
          height: auto;
          bottom: -4rem;
          right: 4.5rem;
        }
        .cargo__content {
          max-width: 40%;
        }
      }
		}

		&__img {
      position: absolute;
      bottom: 0;
      right: 0;
		}


		&__content-title {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 130%;
      color: var(--white);
      margin-bottom: 1.25rem;
		}

		&__content-list {
      margin-left: 1.59375rem;
		}

		&__content-item {
      list-style: disc;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--white);
		}
}

@media (max-width: 800px) {
  .cargo__content-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
  }
  .cargo__content-item {
    font-size: 0.754rem;
  }
  .cargo__item:first-child {
    flex: 0 0 25.673125rem;
    .cargo__img {
      width: 14.3125rem;
      right: 0.52rem;
      bottom: 0.005rem;
    }
  }
  .cargo__item:nth-child(2) {
    flex: 0 0 17.825rem;
    .cargo__img {
      width: 13.4375rem;
    }
  }
  .cargo__item:last-child {
    .cargo__img {
      width: 18.8125rem;
      bottom: -3rem;
      right: 3.5rem;
    }
  }
  .cargo__wrapper {
    gap: 1.25rem 0.625rem;
  }
  .cargo__item {
    padding: 1.114375rem 1.09375rem;
    height: 10.725rem;
}
}

@media (max-width: 550px) {
  .cargo__item {
    flex: 0 0 100% !important;
    height: 9.05101875rem;
  }
  .cargo__item:first-child {
    .cargo__img {
      width: 9.375rem;
      right: -0.40052rem;
      bottom: 0.005rem;
    }
  }
  .cargo__item:nth-child(2) .cargo__img {
    width: 11.1875rem;
    bottom: -0.4rem;
  }
  .cargo__item:last-child .cargo__img {
    width: 9.6875rem;
    bottom: 0.6rem;
        right: -0.85rem;
  }
  .cargo__item {
    padding: 1.114375rem 0.609375rem;
  }
  .cargo__content-list {
    margin-left: 1.259375rem;
  }
  .cargo__content-title {
    margin-bottom: 0.4875rem;
    width: 100%;
  }
  .cargo__wrapper {
    gap: 0.925rem;
  }
  .cargo__item:last-child .cargo__content {
    max-width: 65%;
}
}

