.services {
  padding-bottom: 0;

		&__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.9375rem;
		}

		&__item {
      position: relative;
      border-radius: 0.625rem;
      padding: 2.4375rem 1.937rem 2.75rem 1.937rem;
      background: var(--main);
      &:first-child{
        flex: 0 0 59%;
        background: var(--accent);
        .services__item-content {
          max-width: 23.8125rem;
        }
        .services__item-img {
          width: 21.3125rem;
          height: auto;
          bottom: -2rem;
        }
      }
      &:nth-child(2){
        flex: 0 0 38%;
        .services__item-content {
          max-width: 12.5rem;
        }
        .services__item-img{
          width: 17.25rem;
          height: auto;
        }
      }
      &:nth-child(3){
        flex: 0 0 38%;
        padding-bottom: 5.6rem;
        .services__item-content {
          max-width: 13.5rem;
        }
        .services__item-img{
          width: 24.125rem;
          height: auto;
        }
      }
      &:last-child{
        flex: 0 0 59%;
        .services__item-content {
          max-width: 25.8125rem;
        }
        .services__item-img{
          width: 25.875rem;
          height: auto;
          bottom: 0rem;
        }
      }
		}

		&__item-img {
      position: absolute;
      right: 0;
		}

		&__item-title {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 130%;
      color: var(--white);
      margin-bottom: 1.25rem;
		}

		&__item-text {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--white);
		}
}

@media (max-width: 800px) {
  .services__item-title {
    font-size: 1.25rem;
    line-height: 110%;
    margin-bottom: 0.875rem;
    font-weight: 500;
  }
  .services__item-text {
    font-size: 0.75rem;
  }
  .services__item {
    padding: 1.25rem;
    overflow: hidden;
  }
  .services__item:first-child {
    flex: 0 0 57%;
    .services__item-img {
    width: 13.75rem;
    height: auto;
    bottom: -1.5rem;
    right: -1rem;
  }
  }
  .services__item:nth-child(2) {
    flex: 0 0 40%;
    .services__item-img {
      width: 12.375rem;
      bottom: -0.5rem;
    }
  }
  .services__item:nth-child(3) {
    padding-bottom: 2.46rem;
    flex: 0 0 40%;
    .services__item-img {
      width: 15.9375rem;
      bottom: -1.50rem;
    }
  }
  .services__item:last-child {
    flex: 0 0 57%;
    .services__item-img {
      width: 16.375rem;
      height: auto;
      bottom: -0.2rem;
    }
    .services__item-title {
      width: 80%;
    }
  }
    .services__item-text {
    width: 63%;
  }
  .services__wrapper {
    gap: 1.1875rem;
}

}

@media (max-width: 550px) {
  .services__item {
    flex: 0 0 100% !important;
    padding: 1.00625rem 0.50rem 0.6rem 0.50rem;
    height: 11.575rem;
  }
  .services__item-title {
    margin-bottom: 0.2875rem;
    line-height: 110%;
  }
  .services__wrapper {
    gap: 0.74661875rem;
}

  .services__item:first-child {
    .services__item-img {
      width: 8.4375rem;
      height: auto;
      bottom: -1.05rem;
      right: 0rem;
    }
    .services__item-text {
      width: 81%;
    }
  }
  .services__item:nth-child(2) {
    .services__item-img {
      width: 11.1875rem;
      bottom: -0.5rem;
  }
  .services__item-text {
    width: 87%;
}
  }
  .services__item:nth-child(3){
    .services__item-text {
      width: 66%;
    }
  }
  .services__item:last-child {
    .services__item-img {
      width: 12.1875rem;
      height: auto;
      bottom: -0.2rem;
    }
    .services__item-title {
      width: 100%;
  }
  .services__item-text {
    width: 67%;
}
  }


}



