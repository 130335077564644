.contact {

		&__wrapper {
      overflow: hidden;
      border-radius: 0.625rem;
      box-shadow: 0 0 1.25rem 0 rgba(40, 65, 123, 0.1);
      margin-bottom: 3.75rem;
		}

		&__btns {
      display: flex;
      align-items: center;
		}

		&__btn {
      width: 50%;
      padding: 1.4375rem 0;
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--white);
      background: rgba(42, 64, 123, 0.3);
      &.active {
        background: var(--main);
      }
		}

		&__content {
      display: none;
      padding: 2.5rem 7.3125rem;
      &.active {
        display: block;
      }
		}

		&__content-item {
      display: flex;
      align-items: start;
      justify-content: space-between;
      &:not(:last-child) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid var(--light);
      }
		}

		&__content-title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 130%;
      color: var(--dark);
		}

		&__content-text {
      flex: 0 0 20.04375rem;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: var(--dark);
		}

		&__content-social {
      display: flex;
      align-items: center;
      gap: 0.5rem;
		}

		&__content-item--social {
      align-items: center;
		}
    &__content-phone {
      a {
        display: block;
        &:not(:last-child) {
          margin-bottom: 0.84375rem;
        }
      }
    }
}

.contact__form {
  width: 50.25rem;
  margin: 0 auto;
  &-content {
    border-radius: 0.625rem;
    padding: 2.5rem 1.875rem;
    background: var(--main);
  }
  &-title {
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 2rem;
    line-height: 100%;
    text-align: center;
    color: var(--main);
    margin-bottom: 0.5625rem;
  }
  &-subtitle {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 130%;
    color: var(--dark);
    margin-bottom: 1.875rem;
    text-align: center;
  }
  &-textarea {
    display: block;
    width: 100%;
    border: 1px solid var(--main);
    border-radius: 0.625rem;
    height: 4.8125rem;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 130%;
    color: rgba(7, 17, 38, 0.5);
    padding: 1.1875rem;
    resize: none;
  }
  .form__item-input {
    width: 100%;
  }
  .form__wrap {
    display: flex;
    gap: 1.25rem;
  }
  .form__content {
    flex-direction: column;
    gap: 1.25rem;
    flex: 0 0 22.625rem;
  }
  .form__item-label {
    width: 100%;
  }
  .form__item-btn {
    width: 100%;
  }
  .just-validate-error-label {
    bottom: -1.3125rem;
    left: 2.25rem;
  }

}
.contact__label-checkbox--mobile {
  display: none;
}

@media (max-width: 800px) {
  .contact__btn {
    width: 50%;
    padding: 0.74375rem 0;
  }
  .contact__content-title {
    font-weight: 500;
font-size: 1.25rem;
  }
  .contact__content-text {
    font-size: 1rem;
    flex: 0 0 16.050438rem;
  }
  .contact__content {
    display: none;
    padding: 2.005rem 1.83125rem;
    padding-right: 1.25rem;
    padding-bottom: 2.3rem;
}
.contact__content-item:not(:last-child) {
  padding-bottom: 0.78125rem;
  margin-bottom: 0.78125rem;
}
.contact__wrapper {
  margin-bottom: 2.575rem;
}
.contact__form-subtitle {
  font-weight: 700;
font-size: 1rem;
margin-bottom: 1.25rem;
}
.contact__form {
  max-width: 30.125rem;
}

.contact__form-content {
  border-radius: 0.625rem;
    padding: 1.85rem 3.875rem;
  .form__wrap {
    display: block;
  }
  .form__item-label {
    font-size: 0.875rem;
line-height: 140%;
  }
  .form__item-input {
    margin-top: 0.2rem;
  }
  .form__content {
    gap: 1.00025rem;
    align-items: start;
}
.form__item-label--textarea {
  margin-top: 0.8rem;
}
.contact__form-textarea {
  height: 5.8125rem;
}

.consultation__label-checkbox {
  display: none;
}
.contact__label-checkbox--mobile {
  margin-top: -0.3125rem;
  display: flex;
}
.form__item-btn {
  margin-top: 1.4375rem;
}
}

}

@media (max-width: 550px) {
  .contact__btn {
    padding: 0.74375rem 0;
    font-size: 1rem;
line-height: 100%;
  }
  .contact__content-title{
    font-weight: 700;
    font-size: 1rem;
  }
  .contact__content-text{
    font-size: 0.875rem;
line-height: 140%;
flex: 0 0 100%;
  }
  .contact__content-item {
    flex-direction: column;
    gap: 0.375rem;
  }
  .contact__content {
    padding:1.25rem 2.4375rem;
    padding-right: 2rem;
}
.contact__content-item:not(:last-child) {
  margin-bottom: 0.378125rem;
}
.contact__content-phone a:not(:last-child) {
  margin-bottom: 0.2384375rem;
}
.contact__content-item--social {
  align-items: start;
}
.contact__form {
  max-width: auto;
  width: 100%;
}
.contact__form-title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  margin-bottom: 0.2375rem;
}
.contact__form-subtitle {
  font-size: 0.999rem;
  margin-bottom:1.25rem;
  font-weight: 400;
}
.contact__form-content {
  border-radius: 0.625rem;
  padding: 1.185rem 0.5875rem;
}

.contact__form-content {
  .form__item-label {
    font-size: 0.75rem;
  }
  .form__item-input {
    margin-top: 0.2rem;
    height: 2.625rem;
    font-size: 0.75rem;
    padding: 0 0.625rem;
}
.contact__form-textarea {
  margin-top: 0.3125rem;
  height: 5rem;
  font-size: 0.75rem;
  padding: 0.625rem;
}
.form__content {
  gap: 0.800025rem;
}
.consultation__label-checkbox p {
  font-size: 0.75rem;
}
.form__item-btn {
  margin-top: 0.68994375rem;
  height: 2.625rem;
  font-size: 1rem;
}
}
}
