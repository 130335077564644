.footer {
  background: var(--main);
  padding: 6.25rem 0 1.6875rem;

		&__wrapper {
      display: flex;
      align-items: start;
      justify-content: space-between;
		}

		&__logo {
      flex: 0 0 12.1875rem;
      height: auto;
		}

		&__content {
      display: flex;
      align-items: start;
		}

		&__contact {
      margin-left: 3.6875rem;
      margin-right: 1.9375rem;
		}

		&__contact-item {
      display: flex;
      align-items: center;
      font-family: var(--second-family);
      font-weight: 400;
      font-size: 1rem;
      line-height: 130%;
      color: var(--white);
      gap: 0.625rem;
      &:not(:last-child){
        margin-bottom: 0.5rem;
      }
		}

		&__contact-icon {
      width: 1.25rem;
      height: 1.25rem;
		}
    &__developer {
      display: block;
      margin-top: 0.6875rem;
      text-align: right;
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 130%;
      color: var(--white);
      margin-right: 1rem;
    }
    &__mobile {
      display: none;
    }
}

.footer {
  .header__nav {
    flex: 0 0 55.3125rem;
    border-radius: 0px;
    background: none;
    padding: 0;
    margin-bottom: 2.5rem;
  }
  .header__nav-list {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
  }
  .nav__link {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 130%;
    color: var(--white);
    &:hover {
      // text-shadow: 0px 4px 4px rgba(42, 64, 123, 0.1);
      -webkit-text-stroke: 1px white;
    }
  }
}
#footer__mobile {
  display: none;
}

@media (max-width: 800px) {
  .footer {
    display: none;
  }
  .footer__mobile {
    display: block;
    padding: 3.725rem 0 2.86875rem;
  }

  .footer__mobile {
    .footer__wrapper {
      display: flex;
      justify-content: space-between;
    }
    .header__nav-list {
      flex-direction: column;
    }
    .footer__logo {
      width: 14.375rem;
      height: auto;
      margin-bottom: 1.1rem;
    }
    .footer__contact {
      margin-left: 0rem;
      margin-right: 0rem;
  }
  .footer__contact {
    margin-top: 1.25rem;
  }
  .footer__contact-item {
    font-size: 0.875rem;
  }
  .nav__link {
    font-size: 1rem;
  }
  .footer__btn {
    width: 12.5rem;
    margin-bottom: 2.625rem;
  }
  .footer__wrapper-item:first-child {
    max-width: 14.375rem;
  }
  .header__nav {
    flex: 0 0 auto;
  }
  .header__nav-list {
    gap: 0.825rem;
}
.social {
 justify-content: end;;
}
.footer__developer {
  margin-top: 0;
  margin-right: 0;
  font-size: 0.75rem;
}
  }

}

@media (max-width: 550px) {
  #footer__middle {
    display: none;
  }
  #footer__mobile {
    display: block;
  }

  #footer__mobile {
    padding: 2.4725rem 0 2.86875rem;
    .footer__logo {
      width: 14.875rem;
      margin-bottom: 0.51rem;
    }
    .nav__link {
      font-size: 0.875rem;
    }
    .header__nav-item {
      line-height: normal;
    }
    .header__nav-list {
      gap: 0.233825rem;
  }
  .footer__contact-icon {
    width: 0.875rem;
    height: 0.875rem;
}
.footer__phone {
  margin-top: 0.625rem;
}
.social {
  margin-top: 2rem;
}
.header__nav{
  margin-bottom: 0;
}
.header__contact-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.footer__contact-item {
  gap: 0.2625rem;
}
.footer__contact-item:not(:last-child) {
  margin-bottom: 0.25rem;
}
.footer__btn {
  width: 100%;
  height: 2.625rem;
  font-weight: 500;
font-size: 1rem;
margin: 1.875rem 0;
}
.footer__developer {
  text-align: center;
}
}
}




