html {
  box-sizing: border-box;
  font-size: 1.10990335vw;
}

@media(min-width:1440px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  html {
    box-sizing: border-box;
    font-size: 2.089vw;
  }
}
// @media (max-width: 550px) {
//   html {
//     box-sizing: border-box;
//     font-size: 4.1vw;
//   }
// }

@media (max-width: 550px) {
  html {
    box-sizing: border-box;
    font-size: 5.06991vw;
  }
}

*,
*::before,
*::after {
  // box-sizing: inherit;
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;
}
ul, li {
  padding: 0;
  margin: 0;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
address {
  font-style: normal;
}
a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

input {
  color: var(--dark) !important;
  opacity: 1 !important;
  font-weight: 400 !important;
}

input::placeholder {
  color: rgba(7, 17, 38, 0.5) !important;
}

textarea {
  color: var(--dark) !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  outline: none;
}

textarea::placeholder {
  color: rgba(7, 17, 38, 0.5) !important;
}


.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 1.25rem;
  color: var(--dark);
  font-weight: 400;
  font-family: var(--font-family);
  line-height: 130%;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 800px) {
  .container {
    margin: 0 auto;
    padding: 0 1.25rem;
    max-width: calc(50rem + 1.25rem * 2);
    margin: 0 6px;
  }
}
@media (max-width: 550px) {
  .container {
    margin: 0 auto;
    padding: 0 0.625rem;
  }
}
.container-small {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10.75rem;
}
@media (max-width: 800px) {
  .container-small {
    margin: 0 auto;
    padding: 0 1.25rem;
    max-width: calc(50rem + 1.25rem * 2);
    margin: 0 6px;
  }
}
@media (max-width: 550px) {
  .container-small {
    margin: 0 auto;
    padding: 0 0.625rem;
  }
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  position: relative;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  position: relative;
  border-radius: 0.625rem;
  width:  12.5rem;
  height: 3.125rem;
  background: var(--white);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--main);
  &:hover {
    box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
    background: var(--light);
    transition: background 0.2s ease;
  }
}
.btn-light {
  position: relative;
  border-radius: 0.625rem;
  width:  12.5rem;
  height: 3.125rem;
  background: var(--white);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--main);
  border: 1px solid var(--main);
  &:hover {
    box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
    background: var(--light);
    transition: background 0.2s ease;
  }
}

.btn-dark {
  position: relative;
  border-radius: 0.625rem;
  height: 3.125rem;
  background: var(--main);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  color: var(--white);
  &:hover {
    box-shadow: 0 0 8px 0 rgba(42, 64, 123, 0.4);
    background: #324f9d;
    transition: background 0.2s ease;
  }
}

.btn-color {
  width: 22.75rem;
  height: 3.625rem;
  border-radius: 0.625rem;
  background: var(--accent);
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--white);
  &:hover {
    box-shadow: 0 0 8px 0 rgba(234, 103, 9, 0.4);
    background: #ff7919;
    transition: background 0.2s ease;
  }
}
@media (max-width: 800px) {
  .btn-color {
    height: 3.25rem;
  }
}
@media (max-width: 550px) {
  .btn-color {
    height: 2.625rem;
    font-weight: 700;
    font-size: 1rem !important;
  }
}

.section {
  padding: 6.25rem 0;
}
.section-title {
  font-family: var(--second-family);
  font-weight: 800;
  font-size: 3rem;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  color: var(--main);
  margin-bottom: 3.75rem;
  text-align: center;
}
@media (max-width: 800px) {
  .section {
    padding: 3.75rem 0;
  }
  .section-title {
    font-family: var(--second-family);
    font-size: 2rem;
    line-height: 100%;
    margin-bottom: 3.75rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 550px) {
  .section {
    padding: 2.5rem 0;
  }
  .section-title {
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 130%;
    text-align: center;
    color: var(--main);
    margin-bottom: 1.25rem;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}


